import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';

import { HealthKeys } from 'config';
import getHealthKey from 'helpers/functions/getHealthKey';
import HealthBar from 'components/dumb//HealthBar';
import { HealthCardProps } from '../types';
import * as SC from '../styles';
import { ArrowNextIcon24px } from '../../../../icons/ArrowNextIcon';

function HealthCard({
  status,
  currentHealthFactor,
  futureHealthFactor,
  className,
}: HealthCardProps) {
  const currentHealthKey = getHealthKey(currentHealthFactor);
  const futureHealthKey = getHealthKey(futureHealthFactor);
  const { t } = useTranslation();

  const healthName = () => {
    if (futureHealthKey === HealthKeys.RISKY) {
      return t('lend.risky');
    }
    if (futureHealthKey === HealthKeys.OK) {
      return t('lend.ok');
    }
    return t('lend.good');
  };

  return (
    <SC.Wrapper className={clsx(className, status)}>
      <SC.Header>
        <SC.Health className={futureHealthKey}>{healthName()}</SC.Health>
      </SC.Header>
      <SC.Body className={clsx(className, status, futureHealthKey)}>
        <Trans i18nKey="lend.healthFactor" />
        <SC.VerticallyCenteredFlexContainer>
          <span className={currentHealthKey}>{currentHealthFactor}</span>
          <ArrowNextIcon24px />
          <span className={futureHealthKey}>{futureHealthFactor}</span>
        </SC.VerticallyCenteredFlexContainer>
      </SC.Body>
      <SC.Footer className={status}>
        <HealthBar health={futureHealthFactor} />
      </SC.Footer>
    </SC.Wrapper>
  );
}

export default HealthCard;
