import React from 'react';

export function ExternalIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 5H11.5V4.5H11V5ZM5.35355 11.3536L11.3536 5.35355L10.6464 4.64645L4.64645 10.6464L5.35355 11.3536ZM11 4.5H7V5.5H11V4.5ZM10.5 5V9H11.5V5H10.5Z"
        fill="black"
      />
    </svg>
  );
}

export function ExternalIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 8H16.5V7.5H16V8ZM8.35355 16.3536L16.3536 8.35355L15.6464 7.64645L7.64645 15.6464L8.35355 16.3536ZM16 7.5H10V8.5H16V7.5ZM15.5 8V14H16.5V8H15.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
