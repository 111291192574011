import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IconPlaceholder = styled.div``;

export const Percent = styled.span`
  font-size: ${({ theme }) => theme.typography.pxToRem(-2)};
  line-height: ${({ theme }) => theme.typography.fontSize};
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.04em;
  text-align: center;
  padding: 4px 8px;
  color: ${({ theme }) => theme.palette.textSecondary};
  background: ${({ theme }) => theme.palette.separators.darkerBg};
  box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.raw.white};
  position: relative;
  left: -2px;
  border-radius: 100px;

  &.big {
    font-size: ${({ theme }) => theme.typography.pxToRem(1)};
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.04em;
    padding: 8px 10px;
    background: ${({ theme }) => theme.palette.separators.darkerBg};
    border-radius: 32px;
  }

  &.darkBorder {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.background};
  }
`;
