import React from 'react';

export function ArrowRightIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 8H11M11 8L8 5M11 8L8 11" stroke="currentColor" />
    </svg>
  );
}

export function ArrowRightIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 12H17M17 12L12.5 7.5M17 12L12.5 16.5" stroke="currentColor" />
    </svg>
  );
}
