import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useConnectToMetamask from './useConnectToMetamask';
import ChainId from '../../constants/chainId';
import toastAlert from '../../../alerts/ToastAlert';
import { LoginData } from '../../../api/requestWrappers/AuthRequest';
import { login, logout } from '../../../redux/slices/auth';
import { isAuthenticatedSelector } from '../../../redux/slices/selectors';
import { useAppDispatch } from '../../../hooks/redux';
import { FLP_USE_ETHEREUM_MAINNET } from '../../../helpers/constants/envVars';

const useConnectToMetamaskHelper = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const { connectWallet } = useConnectToMetamask({
    requiredChain: FLP_USE_ETHEREUM_MAINNET
      ? ChainId.ETHEREUM_MAIN_NET
      : ChainId.KOVAN_TEST_NET,

    onMetaMaskNotFound() {
      toastAlert.showWarning(t('metamask.warnings.extensionNotFound'));
    },
    onMetaMaskNotConnected() {
      toastAlert.showWarning(t('metamask.warnings.notConnected'));
      setTimeout(() => window.location.reload(), 3000);
    },
    onAlreadyProcessingEthRequestAccounts() {
      toastAlert.showWarning(
        t('metamask.warnings.alreadyProcessingEthRequestAccounts')
      );
    },
    onUserRejectedToConnect() {
      toastAlert.showWarning(t('metamask.warnings.userRejectedToConnect'));
    },
    onMessageSignaturePending() {
      toastAlert.showWarning(t('metamask.warnings.messageSignaturePending'));
    },
    onMessageSignatureRejected() {
      toastAlert.showWarning(t('metamask.warnings.messageSignatureRejected'));
    },
    onCouldntGetAddress() {
      toastAlert.showWarning(t('metamask.warnings.couldntGetAddress'));
    },
    userIsLoggedIn() {
      return isAuthenticated;
    },
    async onLogin(loginData: LoginData) {
      try {
        await dispatch(login(loginData)).unwrap();
      } catch (e) {
        toastAlert.showWarning(t('metamask.warnings.loginFailed'));
      }
    },
    onRequiredChainNotFound() {
      toastAlert.showWarning(
        t('metamask.warnings.requiredChainNotFound', {
          network: t(
            `metamask.networks.${
              FLP_USE_ETHEREUM_MAINNET ? 'mainnet' : 'kovan'
            }`
          ),
        })
      );
    },
    onChainChanged() {
      dispatch(logout());
    },
    onAccountsChanged() {
      dispatch(logout());
    },
  });

  return { connectWallet };
};

export default useConnectToMetamaskHelper;
