import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const Notifications = lazy(
  () => import('components/smart/containers/Notifications')
);

const NOTIFICATIONS_INDEX: Route = {
  component: Notifications,
  path: PATH.NOTIFICATIONS.INDEX,
  isExact: true,
  isProtected: true,
};

export default [NOTIFICATIONS_INDEX];
