import React from 'react';

export function LinkIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.66667 6.66667L3.64298 7.64297C2.34123 8.94472 2.34123 11.0553 3.64298 12.357C4.94473 13.6588 7.05527 13.6588 8.35702 12.357L9.33333 11.3333M6.66667 4.66667L7.64298 3.64297C8.94473 2.34122 11.0553 2.34122 12.357 3.64297C13.6588 4.94472 13.6588 7.05527 12.357 8.35702L11.3333 9.38071M10.3333 5.66667L5.66667 10.3333"
        stroke="black"
      />
    </svg>
  );
}

export function LinkIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 10L5.46447 11.4645C3.51184 13.4171 3.51185 16.5829 5.46447 18.5355C7.41709 20.4881 10.5829 20.4881 12.5355 18.5355L14 17M10 7L11.4645 5.46446C13.4171 3.51184 16.5829 3.51184 18.5355 5.46446C20.4882 7.41708 20.4882 10.5829 18.5355 12.5355L17 14.0711M15.5 8.5L8.5 15.5"
        stroke="black"
      />
    </svg>
  );
}
