import clsx from 'clsx';
import { TokenIconSize } from 'helpers/functions/getTokenIcon';
import {
  displayPrice,
  displayWeiAsValueInAssetUnits,
  wei_to_valueInAssetUnits,
} from 'helpers/functions/numbers';
import DateFormats from 'helpers/classes/DateFormats';
import CurrencyOffers from 'components/dumb/CurrencyOffers';
import useFindAssetInfo from 'ethereum/hooks/useFindAssetInfo';
import { useEffect, useState } from 'react';
import { Asset } from 'api/requestWrappers/AssetsRequest';
import toastAlert from 'alerts/ToastAlert';
import useGetAssetValueInUsdValue from 'ethereum/hooks/useGetAssetValueInUsdValue';
import { useTranslation } from 'react-i18next';
import { InitialCardProps } from '../types';
import * as SC from '../styles';

function InitialCard({
  status,
  assetName,
  assetAddress,
  amount,
  annualInterestRate,
  maturityDate,
  className,
}: InitialCardProps) {
  const { t } = useTranslation();
  const { getAssetValueInUsdValue, ethPriceInUsd } =
    useGetAssetValueInUsdValue();
  const { findAssetInfo, supportedAssetsFetched } = useFindAssetInfo();

  const [asset, setAsset] = useState<Asset | null>(null);
  const [usdEstimate, setUsdEstimate] = useState(0);

  const readyToShowAssetInfo =
    assetAddress && amount && supportedAssetsFetched && ethPriceInUsd;

  useEffect(() => {
    if (!readyToShowAssetInfo) {
      return;
    }

    const assetInfo = findAssetInfo(assetAddress);

    if (!assetInfo) {
      toastAlert.showError('Asset not found!');
      throw new Error('Asset not found!');
    }
    setAsset(assetInfo);

    getAssetValueInUsdValue(
      assetInfo,
      wei_to_valueInAssetUnits(amount.toString(), assetInfo.decimals)
    )
      .then((value) => {
        if (value) {
          setUsdEstimate(value);
        }
      })
      .catch((e) => {
        console.error(e);
        toastAlert.showError(
          t('general.assetValueInUsdError', {
            count: 1,
          })
        );
      });
  }, [readyToShowAssetInfo]);

  return (
    <SC.Wrapper className={clsx(className, status)}>
      <SC.Header>
        <CurrencyOffers
          assetName={assetName}
          annualInterestRate={Number(annualInterestRate)}
          size={TokenIconSize.BIG}
        />
      </SC.Header>
      <SC.Body>
        {amount && asset
          ? displayWeiAsValueInAssetUnits(amount.toString(), asset.decimals)
          : '0.00'}{' '}
        {assetName}
        <span>{displayPrice(usdEstimate)}</span>
      </SC.Body>
      <SC.Footer className={status}>
        {DateFormats.fullDateAlt(maturityDate)}
      </SC.Footer>
    </SC.Wrapper>
  );
}

export default InitialCard;
