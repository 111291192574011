import { lazy } from 'react';
import { Route } from './types';
import PATH from './paths';
import { FLP_USE_ETHEREUM_MAINNET } from '../helpers/constants/envVars';

const TimeMachine = lazy(
  () => import('components/smart/containers/TimeMachine')
);

const TIME_MACHINE_INDEX: Route = {
  component: TimeMachine,
  path: PATH.TIME_MACHINE.INDEX,
  isExact: true,
  isProtected: false,
};

const routes = FLP_USE_ETHEREUM_MAINNET ? [] : [TIME_MACHINE_INDEX];

export default routes;
