import React from 'react';

export function ArrowPrevIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 8L5.64645 7.64645L5.29289 8L5.64645 8.35355L6 8ZM6.35355 8.35355L9.35355 5.35355L8.64645 4.64645L5.64645 7.64645L6.35355 8.35355ZM5.64645 8.35355L8.64645 11.3536L9.35355 10.6464L6.35355 7.64645L5.64645 8.35355Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ArrowPrevIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 12L8.64645 11.6464L8.29289 12L8.64645 12.3536L9 12ZM9.35355 12.3536L13.8536 7.85355L13.1464 7.14645L8.64645 11.6464L9.35355 12.3536ZM8.64645 12.3536L13.1464 16.8536L13.8536 16.1464L9.35355 11.6464L8.64645 12.3536Z"
        fill="currentColor"
      />
    </svg>
  );
}
