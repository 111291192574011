import React from 'react';

export function SortDownIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 9.66669L7.64645 10.0202L8 10.3738L8.35355 10.0202L8 9.66669ZM8.35355 10.0202L11.3536 7.02024L10.6464 6.31313L7.64645 9.31313L8.35355 10.0202ZM8.35355 9.31313L5.35355 6.31313L4.64645 7.02024L7.64645 10.0202L8.35355 9.31313Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function SortDownIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 14.5L11.6464 14.8536L12 15.2071L12.3536 14.8536L12 14.5ZM12.3536 14.8536L16.8536 10.3536L16.1464 9.64648L11.6464 14.1465L12.3536 14.8536ZM12.3536 14.1465L7.85355 9.64648L7.14645 10.3536L11.6464 14.8536L12.3536 14.1465Z"
        fill="currentColor"
      />
    </svg>
  );
}
