import React from 'react';

export function CheckIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5 10.5L6.14645 10.8536L6.5 11.2071L6.85355 10.8536L6.5 10.5ZM6.85355 10.8536L11.8536 5.85355L11.1464 5.14645L6.14645 10.1464L6.85355 10.8536ZM6.85355 10.1464L4.35355 7.64645L3.64645 8.35355L6.14645 10.8536L6.85355 10.1464Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CheckIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.75 15.75L9.39645 16.1036L9.75 16.4571L10.1036 16.1036L9.75 15.75ZM10.1036 16.1036L17.6036 8.60355L16.8964 7.89645L9.39645 15.3964L10.1036 16.1036ZM10.1036 15.3964L6.35355 11.6464L5.64645 12.3536L9.39645 16.1036L10.1036 15.3964Z"
        fill="black"
      />
    </svg>
  );
}
