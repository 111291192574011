import aaveAddresses from 'ethereum/constants/aaveAddresses';

const getVariableDebTokenAddress = (
  tokenContractAddress: string
): string | undefined =>
  aaveAddresses.proto.find(
    (tokenData) =>
      tokenData.address.toLowerCase() === tokenContractAddress.toLowerCase()
  )?.variableDebtTokenAddress;

export default getVariableDebTokenAddress;
