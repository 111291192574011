import ChainId from 'ethereum/constants/chainId';
import { FLP_USE_ETHEREUM_MAINNET } from './envVars';

const { ethereum } = window;

const mainnetMismatch =
  FLP_USE_ETHEREUM_MAINNET && ethereum?.chainId !== ChainId.ETHEREUM_MAIN_NET;

const testnetMismatch =
  !FLP_USE_ETHEREUM_MAINNET && ethereum?.chainId !== ChainId.KOVAN_TEST_NET;

const appChainMismatchWithClientChain = Boolean(
  ethereum?.isConnected() &&
    ethereum?.chainId &&
    (mainnetMismatch || testnetMismatch)
);

export default appChainMismatchWithClientChain;
