import { AuthState } from './auth';
import { AssetsState } from './assets';
import { NotificationsState } from './notifications';

export const isAuthenticatedSelector = ({ auth }: { auth: AuthState }) =>
  auth.isAuthenticated;

export const userAddressSelector = ({ auth }: { auth: AuthState }) =>
  auth.user.address;

export const userDisplayNameSelector = ({ auth }: { auth: AuthState }) =>
  auth.user.displayName;

export const userIdSelector = ({ auth }: { auth: AuthState }) => auth.user.id;

export const userHasClaimedMinersSelector = ({ auth }: { auth: AuthState }) =>
  auth.user.hasClaimedMiners;

export const supportedAssetsSelector = ({ assets }: { assets: AssetsState }) =>
  assets.assets;

export const unreadNotificationsSelector = ({
  notifications,
}: {
  notifications: NotificationsState;
}) => notifications.unread;
