import { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PATH, { LendApproveDelegationParams } from 'routes/paths';
import { KnownTokens } from 'config';
import LendCard from 'components/dumb/MiniCards/Lend';
import { LendCardStatus } from 'components/dumb/MiniCards/Lend/types';
import ModalWrapper from 'components/smart/containers/wrappers/ModalWrapper';
import { ArrowNextIcon24px } from 'components/icons/ArrowNextIcon';
import IconButton from 'components/dumb/IconButton';
import { CloseIcon24px } from 'components/icons/CloseIcon';
import useApproveDelegation from 'ethereum/aave/hooks/useApproveDelegation';
import {
  marketRequest,
  MarketRequestApiResponse,
} from 'api/requestWrappers/MarketRequest';
import { BigNumber } from 'ethers';
import useGetHealthFactorOfAddress from 'ethereum/aave/hooks/useGetHealthFactorOfAddress';
import getVariableDebTokenAddress from 'helpers/functions/getVariableDebTokenAddress';
import toastAlert from 'alerts/ToastAlert';
import { userAddressSelector } from 'redux/slices/selectors';
import { useAppSelector } from 'hooks/redux';
import useConnectToMetamaskHelper from 'ethereum/metamask/hooks/useConnectToMetamaskHelper';
import { ClaimedMinersResponseTypes } from 'api/requestWrappers/ClaimMinerRequest';
import { filrepRequest } from 'api/requestWrappers/FilrepRequest';
import { calcReputationScore } from 'helpers/functions/reputationScore';
import { ApplicationOfferApiResponse } from 'api/requestWrappers/types/general';
import { ArrowPrevIcon24px } from 'components/icons/ArrowPrevIcon';
import useQuery from 'hooks/useQuery';
import Spinner from 'components/dumb/Spinner';
import useFindAssetInfo from 'ethereum/hooks/useFindAssetInfo';
import TypeChecker from 'helpers/classes/TypeChecker';
import * as SC from './styles';
import { TabKeys } from '../constant';
import Tooltip from '../../../../dumb/Tooltip';

interface MinerData {
  trustScore: number;
  trustScoreIsAvg: boolean;
  companyName: string;
  minerIds: string[];
}

interface MarketRequest extends MarketRequestApiResponse {
  borrowerAddress: string;
  currentHealthFactorOfBorrower: number;
  futureHealthFactorOfBorrower: number;
  minerData: MinerData;
}

interface Application extends ApplicationOfferApiResponse {
  minersScore: number;
  minersScoreIsAvg: boolean;
}

export const APPLICATIONS_QUERY_PARAM = 'applications';

function Step1({
  onClose,
  onSelect,
  request,
  applications,
  applicationsLoading,
}: {
  onClose: () => void;
  onSelect: (applicantId: number) => void;
  request: MarketRequest | null;
  applications: Application[];
  applicationsLoading: boolean;
}) {
  const { t } = useTranslation();

  return (
    <ModalWrapper
      title="Step 1 of 2"
      subtitle="Select application"
      footer={
        <IconButton onClick={onClose}>
          <CloseIcon24px />
        </IconButton>
      }
    >
      <SC.Wrapper>
        <LendCard
          status={LendCardStatus.Initial}
          assetName={request?.assetName ? request.assetName : KnownTokens.ETH}
          assetAddress={request?.assetAddress}
          annualInterestRate={
            request?.annualInterestRate ? +request.annualInterestRate : 0
          }
          amount={request?.amount}
          maturityDate={
            request?.dueDate ? new Date(request.dueDate) : new Date()
          }
        />
        <SC.Separator>
          <ArrowNextIcon24px />
        </SC.Separator>
        {applicationsLoading ? (
          <Spinner />
        ) : (
          !applications.length && (
            <SC.NoApplicationsH3>
              {t('lend.noApplications')}
            </SC.NoApplicationsH3>
          )
        )}

        {applications.map((application, index) => {
          if (!application) {
            return (
              <SC.MockCard
                key={String(index)}
                style={
                  applications.length - 1 === index
                    ? undefined
                    : { marginRight: '16px' }
                }
              >
                {t('lend.appNoMinersClaimed')}
              </SC.MockCard>
            );
          }

          return (
            <LendCard
              key={String(index)}
              style={
                applications.length - 1 === index
                  ? undefined
                  : { marginRight: '16px' }
              }
              onSelectCard={() => onSelect(application.applicantId)}
              status={LendCardStatus.Trust}
              issuerName={application.miners[0].companyName || 'Name'}
              issuerMinersText={
                application.miners.length
                  ? t('lend.minersWithCount', {
                      count: application.miners.length,
                    })
                  : t('lend.miners')
              }
              issuerMiners={
                application.miners.map((miner) => ({
                  minerId: miner.minerId,
                })) ?? []
              }
              trustScore={application.minersScore}
              trustScoreIsAvg={application.minersScoreIsAvg}
            />
          );
        })}
      </SC.Wrapper>
    </ModalWrapper>
  );
}

function Step2({
  onClose,
  onBack,
  onApprove,
  request,
  application,
}: {
  onClose: () => void;
  onBack: () => void;
  onApprove: () => void;
  request: MarketRequest | null;
  application: Application | undefined;
}) {
  const { t } = useTranslation();

  const getLendCardProps = () => {
    if (application) {
      return {
        issuerName: application.miners[0].companyName || 'Name',
        issuerMinersText: application.miners.length
          ? t('lend.minersWithCount', {
              count: application.miners.length,
            })
          : t('lend.miners'),
        issuerMiners:
          application.miners.map((miner) => ({
            minerId: miner.minerId,
          })) ?? [],
        trustScore: application.minersScore,
        trustScoreIsAvg: application.minersScoreIsAvg,
      };
    }

    return {
      issuerName: request?.minerData.companyName || 'Name',
      issuerMinersText: request?.minerData?.minerIds.length
        ? t('lend.minersWithCount', {
            count: request?.minerData.minerIds.length,
          })
        : t('lend.miners'),
      issuerMiners:
        request?.minerData.minerIds.map((minerId) => ({
          minerId,
        })) ?? [],
      trustScore: request?.minerData.trustScore,
      trustScoreIsAvg: request?.minerData.trustScoreIsAvg,
    };
  };

  const approveButtonIsDisabled =
    (request?.futureHealthFactorOfBorrower ?? 0) <= 2;
  const approveButton = (
    <SC.StyledCardButton
      onClick={onApprove}
      isDisabled={approveButtonIsDisabled}
    >
      {t('lend.approveApplication')}
    </SC.StyledCardButton>
  );

  return (
    <ModalWrapper
      {...(application ? { title: 'Step 2 of 2' } : {})}
      subtitle="Check health & approve"
      footer={
        <>
          {application && (
            <IconButton onClick={onBack}>
              <ArrowPrevIcon24px />
            </IconButton>
          )}
          <IconButton onClick={onClose}>
            <CloseIcon24px />
          </IconButton>
        </>
      }
    >
      <SC.Wrapper>
        <LendCard
          status={LendCardStatus.Initial}
          assetName={request?.assetName ? request.assetName : KnownTokens.ETH}
          assetAddress={request?.assetAddress}
          annualInterestRate={
            request?.annualInterestRate ? +request.annualInterestRate : 0
          }
          amount={request?.amount}
          maturityDate={
            request?.dueDate ? new Date(request.dueDate) : new Date()
          }
        />
        <SC.Separator>
          <ArrowNextIcon24px />
        </SC.Separator>
        <SC.StyledCard status={LendCardStatus.Trust} {...getLendCardProps()} />
        <SC.Separator>
          <ArrowNextIcon24px />
        </SC.Separator>
        <SC.StyledCard
          status={LendCardStatus.Health}
          currentHealthFactor={request?.currentHealthFactorOfBorrower ?? 0}
          futureHealthFactor={request?.futureHealthFactorOfBorrower ?? 0}
        />
        <SC.Separator>
          <ArrowNextIcon24px />
        </SC.Separator>
        {approveButtonIsDisabled ? (
          <Tooltip text={t('lend.futureHealthFactorAtLeastOne')}>
            {approveButton}
          </Tooltip>
        ) : (
          approveButton
        )}
      </SC.Wrapper>
    </ModalWrapper>
  );
}

function Approve(): ReactElement {
  useConnectToMetamaskHelper();
  const { query } = useQuery();
  const [step, setStep] = useState(query.get(APPLICATIONS_QUERY_PARAM) ? 0 : 1);
  const { t } = useTranslation();
  const history = useHistory();
  const [applications, setApplications] = useState<Application[]>([]);
  const [application, setApplication] = useState<Application | undefined>(
    undefined
  );
  const [applicationsLoading, setApplicationsLoading] = useState(true);
  const requestId = useParams<LendApproveDelegationParams>().id;
  const [marketReq, setMarketReq] = useState<MarketRequest | null>(null);
  const { approveDelegation } = useApproveDelegation();
  const { getHealthFactorOfAddress } = useGetHealthFactorOfAddress();
  const { findAssetInfo, supportedAssetsFetched } = useFindAssetInfo();
  const userAddress = useAppSelector(userAddressSelector);

  useEffect(() => {
    if (!userAddress || !supportedAssetsFetched) {
      return;
    }
    if (step === 0) {
      marketRequest.getApplicationsForOffer(+requestId).then((response) => {
        const applicationsToSet: Record<number, Application> = {};
        const applicantMinersDictionary: Record<number, string[]> = {};

        const allMinerIds = response.applications.reduce(
          (accumulator: string[], { applicantId, miners, ...rest }) => {
            applicationsToSet[applicantId] = {
              applicantId,
              miners,
              ...rest,
              minersScore: 0,
              minersScoreIsAvg: false,
            };
            const applicantMinerIds = miners.map((miner) => miner.minerId);
            applicantMinersDictionary[applicantId] = applicantMinerIds;
            // eslint-disable-next-line no-param-reassign
            accumulator = [
              ...accumulator,
              ...applicantMinerIds.filter(
                (requestMinerId) => !accumulator.includes(requestMinerId)
              ),
            ];
            return accumulator;
          },
          []
        );

        filrepRequest
          .getMiners(allMinerIds)
          .then((minersDictionary) => {
            Object.entries(applicantMinersDictionary).forEach(
              ([applicantId, minerIds]) => {
                const { isAvg, score } = calcReputationScore(
                  minerIds
                    .map((minerId) => minersDictionary[minerId])
                    .filter((miner) => miner)
                );
                applicationsToSet[+applicantId] = {
                  ...applicationsToSet[+applicantId],
                  minersScore: score ?? 0,
                  minersScoreIsAvg: Boolean(isAvg),
                };
              }
            );

            setApplications(Object.values(applicationsToSet));
          })
          .finally(() => setApplicationsLoading(false));
      });
    }
    marketRequest
      .findMarketRequest(+requestId, [{ key: 'include', value: 'miners' }])
      .then(
        ({
          borrower: { address, miners },
          request,
        }: {
          borrower: { address: string; miners: ClaimedMinersResponseTypes[] };
          request: MarketRequestApiResponse;
        }) =>
          filrepRequest
            .getMiners(miners.map((miner) => miner.minerId))
            .then((minersDictionary) => {
              const { isAvg, score } = calcReputationScore(
                Object.values(minersDictionary)
              );

              const asset = findAssetInfo(request.assetAddress);

              if (!asset) {
                throw new Error('Asset not found');
              }

              getHealthFactorOfAddress(userAddress, {
                asset,
                amountInWei: request.amount,
              }).then((healthFactors) => {
                if (TypeChecker.isNumber(healthFactors)) {
                  throw new Error('healthFactors must not be a number!');
                }

                setMarketReq({
                  ...request,
                  borrowerAddress: address,
                  currentHealthFactorOfBorrower:
                    healthFactors.currentHealthFactor,
                  futureHealthFactorOfBorrower:
                    healthFactors.futureHealthFactor,
                  minerData: {
                    trustScore: score ?? 0,
                    trustScoreIsAvg: isAvg ?? false,
                    companyName: miners[0]?.companyName ?? '',
                    minerIds: miners.map((miner) => miner.minerId),
                  },
                });
              });
            })
      );
  }, [requestId, userAddress, step, supportedAssetsFetched]);

  const handlerSelectApplication = (applicantId: number) => {
    setApplication(
      applications.find((app) => app?.applicantId === applicantId)
    );
    setStep(1);
  };

  const handlerApprove = () => {
    if (!marketReq) {
      toastAlert.showError(t('general.unknownError'));
      return;
    }

    const variableDebtTokenAddress = getVariableDebTokenAddress(
      marketReq.assetAddress
    );

    if (!variableDebtTokenAddress) {
      console.error(
        "Couldn't find variable debt token address for asset address!"
      );
      toastAlert.showError(t('general.unknownError'));
      return;
    }

    const borrowerAddress = application
      ? application.user.address
      : marketReq.borrowerAddress;

    approveDelegation(
      marketReq.id,
      borrowerAddress,
      variableDebtTokenAddress,
      BigNumber.from(marketReq.amount),
      application?.applicantId
    ).then(() => {
      history.push({
        pathname: PATH.LEND.INDEX,
        search: `?type=${TabKeys.MY_OFFERS}`,
      });
    });
  };

  const onGoBack = () => {
    setStep(0);
  };

  const onClose = () => {
    history.push(PATH.MARKET.INDEX);
  };

  switch (step) {
    case 1:
      return (
        <Step2
          onClose={onClose}
          onBack={onGoBack}
          onApprove={handlerApprove}
          request={marketReq}
          application={application}
        />
      );
    case 0:
    default:
      return (
        <Step1
          onClose={onClose}
          onSelect={handlerSelectApplication}
          applications={applications}
          applicationsLoading={applicationsLoading}
          request={marketReq}
        />
      );
  }
}

export default Approve;
