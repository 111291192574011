import { AxiosRequestConfig } from 'axios';
import AbstractRequest from './AbstractRequest';
import AuthClientStore from '../../clientStore/AuthClientStore';
import TypeChecker from '../../helpers/classes/TypeChecker';
import { ApiMethod, AUTH_ME_SUBROUTE } from './constants';

export interface LoginData {
  address: string;
  message: string;
  signature: string;
}

export class AuthRequest extends AbstractRequest {
  private readonly authClientStoreService: typeof AuthClientStore;

  private _token: string | null;

  private requestInterceptorId: number | null;

  constructor(
    authClientStoreService: typeof AuthClientStore = AuthClientStore
  ) {
    super();
    this.authClientStoreService = authClientStoreService;
    this._token = AuthClientStore.getToken();
    this.requestInterceptorId = null;
  }

  get apiResource(): string {
    return 'auth';
  }

  get routes(): Record<string, string> {
    return {
      LOGIN: `${this.apiRoute}/login`,
      ME: `${this.apiUrl}${AUTH_ME_SUBROUTE}`,
    };
  }

  get token(): string | null {
    return this._token;
  }

  set token(token: string | null) {
    if (token) {
      this.authClientStoreService.setToken(token);
    } else {
      this.authClientStoreService.removeToken();
    }

    this._token = token;
  }

  setAuthorizationHeader() {
    if (!this.token) {
      return;
    }
    this.requestInterceptorId = this.addRequestInterceptor(
      (config: AxiosRequestConfig) => {
        if (config?.headers) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${this.token}`;
        }

        return config;
      }
    );
  }

  unsetAuthorizationHeader() {
    if (TypeChecker.isNumber(this.requestInterceptorId)) {
      this.removeRequestInterceptor(this.requestInterceptorId);
    }
    this.requestInterceptorId = null;
  }

  async login(data: LoginData) {
    const response = await this.request(
      ApiMethod.POST,
      this.routes.LOGIN,
      data
    );
    this.token = response.data.access_token;
    this.setAuthorizationHeader();

    return response.data;
  }

  async logout() {
    this.token = null;
    this.unsetAuthorizationHeader();

    return null;
  }

  async me() {
    this.setAuthorizationHeader();
    try {
      const result = await this.request(ApiMethod.GET, this.routes.ME);

      return result.data;
    } catch (e) {
      this.unsetAuthorizationHeader();
      throw e;
    }
  }
}

export const authRequest = new AuthRequest();
