import React from 'react';

export function QuestionAltIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.33337 6.33332C6.33337 5.41285 7.07957 4.66666 8.00004 4.66666C8.92052 4.66666 9.66671 5.41285 9.66671 6.33332C9.66671 7.2538 8.92052 7.99999 8.00004 7.99999V9.33332M8.00004 9.99999V11.3333"
        stroke="black"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function QuestionAltIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.5 9.5C9.5 8.11929 10.6193 7 12 7C13.3807 7 14.5 8.11929 14.5 9.5C14.5 10.8807 13.3807 12 12 12V14M12 15V17"
        stroke="black"
        strokeLinejoin="round"
      />
    </svg>
  );
}
