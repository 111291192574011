import { Redirect, Route } from 'react-router-dom';
import PATH from 'routes/paths';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from 'redux/slices/selectors';
import { Route as Props } from 'routes/types';

export default function AppRoute({
  component: Component,
  path,
  isProtected,
  isExact,
}: Props): ReactElement {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  return (
    <Route
      path={path}
      exact={isExact}
      render={() => {
        if (isProtected && !isAuthenticated) {
          return <Redirect to={PATH.INDEX} />;
        }
        return <Component />;
      }}
    />
  );
}
