class AbstractClientStore {
  static storage: Storage;

  static setStorage(storage: Storage): void {
    this.storage = storage;
  }
}

AbstractClientStore.setStorage(localStorage);

export default AbstractClientStore;
