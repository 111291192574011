export default {
  metamask: {
    networks: {
      kovan: 'Kovan Test Network',
      mainnet: 'Ethereum Mainnet',
    },
    warnings: {
      extensionNotFound:
        'MetaMask not found. Please install the browser extension.',
      notConnected:
        'MetaMask is not initialized. Refreshing the page in 3 seconds...',
      alreadyProcessingEthRequestAccounts:
        'Open MetaMask extension to connect.',
      userRejectedToConnect:
        'You need to accept our website in MetaMask to connect.',
      messageSignaturePending:
        'Open MetaMask extension and sign the pending message to connect.',
      messageSignatureRejected: 'You need to sign the message to connect.',
      couldntGetAddress:
        "Couldn't get your address to connect. Please try again.",
      loginFailed: 'Login failed. Please try again later.',
      requiredChainNotFound: 'You need to be on {{network}} to connect.',
      approveDelegationPending:
        'Open MetaMask extension and approve the pending credit delegation to continue.',
      approveDelegationRejected:
        'You need to approve the credit delegation to continue.',
      executeBorrowPending:
        'Open MetaMask extension and execute borrow to get the funds.',
      executeBorrowRejected:
        'You need to execute the borrow to get the credit.',
      approveTokenSpendPending:
        'Open MetaMask extension and give permission to spend tokens.',
      approveTokenSpendRejected:
        'You need to give permission to spend the funds to continue.',
      approveTokenSpendError:
        'An error has occurred while giving permission to spend funds. Please try again later.',
      repayAavePending:
        'Open MetaMask extension and accept the transaction to repay the debt.',
      repayAaveRejected: 'You need to accept repayment to continue.',
      repayAaveError:
        'An error has occurred while repaying the debt. Please try again later.',
      transferTokensPending:
        'Open MetaMask extension and transfer the tokens to repay the debt.',
      transferTokensRejected: 'You need to accept token transfer to continue.',
      transferTokensError:
        'An error has occurred while transferring tokens. Please try again later.',
      repaymentProcessPending:
        'A repayment process is already in progress. Open MetaMask extension and continue the process.',
    },
    success: {
      borrowExecuted:
        'Borrow executed. Wait a few seconds and refresh the page to see your loan being active.',
    },
  },
};
