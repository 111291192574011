import axios, { AxiosRequestConfig, Method } from 'axios';

abstract class AbstractExternalRequest {
  abstract get apiUrl(): string;

  abstract get routes(): Record<string, string>;

  async request(
    method: Method,
    url: string,
    data?: unknown,
    extraConfig?: AxiosRequestConfig
  ) {
    return axios.create()({ method, url, data, ...extraConfig });
  }
}

export default AbstractExternalRequest;
