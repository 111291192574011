import React from 'react';

export function FILIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="24"
      height="24"
      viewBox=" 0 0 64 64"
      {...props}
    >
      <title>FIL</title>
      <rect x="0" y="0" width="64" height="64" rx="16%" fill="#0090FF" />
      <path
        fill="#fefefe"
        d="M46.4 12.8c-6.5-4.1-11.1 4.9-11.2 5.2-1.2 2.2-2.1 5.7-2.7 8.3L24 25l-.3 2.3 8.3 1.2c-.2 1.2-.3 2-.3 2l-.5 2.5v.1L23 31.9l-.3 2.3 8.2 1.2c-2.1 10.8-3.7 14.1-5.3 15.9-.3.4-2.6 1.9-3.8-.8-.8-1.9-2.3-1.7-2.3-1.7-.5-.1-2.5 1.2.6 3.2 6.4 4.1 11.1-4.9 11.2-5.2 1.2-2.2 2.7-8.3 3.3-10.9l8.5 1.3.3-2.3-8.4-1.2c.2-1.2.3-2 .3-2l.4-2.5v-.1l8.3 1.2.3-2.3-8.2-1.2C38.4 16 39.4 15.4 41 13.6c.3-.4 2.6-1.9 3.8.8.8 1.9 2.3 1.7 2.3 1.7.4 0 2.4-1.3-.7-3.3"
      />
    </svg>
  );
}

export function FILIcon32px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="32"
      height="32"
      viewBox=" 0 0 64 64"
      {...props}
    >
      <title>FIL</title>
      <rect x="0" y="0" width="64" height="64" rx="16%" fill="#0090FF" />
      <path
        fill="#fefefe"
        d="M46.4 12.8c-6.5-4.1-11.1 4.9-11.2 5.2-1.2 2.2-2.1 5.7-2.7 8.3L24 25l-.3 2.3 8.3 1.2c-.2 1.2-.3 2-.3 2l-.5 2.5v.1L23 31.9l-.3 2.3 8.2 1.2c-2.1 10.8-3.7 14.1-5.3 15.9-.3.4-2.6 1.9-3.8-.8-.8-1.9-2.3-1.7-2.3-1.7-.5-.1-2.5 1.2.6 3.2 6.4 4.1 11.1-4.9 11.2-5.2 1.2-2.2 2.7-8.3 3.3-10.9l8.5 1.3.3-2.3-8.4-1.2c.2-1.2.3-2 .3-2l.4-2.5v-.1l8.3 1.2.3-2.3-8.2-1.2C38.4 16 39.4 15.4 41 13.6c.3-.4 2.6-1.9 3.8.8.8 1.9 2.3 1.7 2.3 1.7.4 0 2.4-1.3-.7-3.3"
      />
    </svg>
  );
}
