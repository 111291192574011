import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: 100%;
  }

  body {
    margin: 0;
    font-family: ${({ theme }) =>
      theme.typography
        .fontFamily}, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: ${({ theme }) => theme.typography.fontSize}; // 1rem (16px)
    line-height: 16px;
    font-weight: ${({ theme }) => theme.typography.fontWeight};
    color: ${({ theme }) => theme.palette.raw.black};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  h3 {
    margin: 0;
    font-size: ${({ theme }) => theme.typography.pxToRem(4)};
    line-height: 24px;
    font-weight: 600;
  }

  button {
    border: none;
    cursor: pointer;
  }

  button, input {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
  
  input {
    border: none;
    :focus {
      outline: none;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }) => theme.palette.text};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.palette.text};
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: ${({ theme }) => theme.palette.text};
  }
  ,
`;

export default GlobalStyle;
