import { LendCardPropTypes, LendCardStatus } from './types';
import InitialCard from './InitialCard';
import HealthCard from './HealthCard';
import TrustCard from './TrustCard';

function LendCard(props: LendCardPropTypes) {
  // eslint-disable-next-line react/destructuring-assignment
  switch (props.status) {
    case LendCardStatus.Initial:
      return <InitialCard {...props} />;
    case LendCardStatus.Health:
      return <HealthCard {...props} />;
    case LendCardStatus.Trust:
      return <TrustCard {...props} />;

    default:
      return null;
  }
}

export default LendCard;
