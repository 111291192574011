import { useEffect, useState } from 'react';
import { coinGeckoRequest } from '../../api/requestWrappers/CoinGeckoRequest';

const useGetEthPriceInUsd = () => {
  const [price, setPrice] = useState<number | null>(null);

  useEffect(() => {
    const getPrice = async () => {
      setPrice(await coinGeckoRequest.getEthPriceInUsd());
    };
    getPrice();
  }, []);

  return price;
};

export default useGetEthPriceInUsd;
