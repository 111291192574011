import { KnownTokens } from 'config';
import { ETHIcon24px, ETHIcon32px } from 'components/icons/currency/ETHIcon';
import { DAIIcon24px, DAIIcon32px } from 'components/icons/currency/DAIIcon';
import { USDCIcon24px, USDCIcon32px } from 'components/icons/currency/USDCIcon';
import { FILIcon24px, FILIcon32px } from 'components/icons/currency/FILIcon';
import {
  RENFILIcon24px,
  RENFILIcon32px,
} from 'components/icons/currency/RENFILIcon';
import { USDTIcon24px, USDTIcon32px } from 'components/icons/currency/USDTIcon';
import {
  CoinPlaceholder24px,
  CoinPlaceholder32px,
} from 'components/icons/currency/CoinPlaceholderIcon';

export enum TokenIconSize {
  DEFAULT = 24,
  BIG = 32,
}

export function getTokenIcon(
  symbol: KnownTokens | string,
  size?: TokenIconSize
) {
  switch (symbol) {
    case KnownTokens.WETH:
    case KnownTokens.ETH:
      switch (size) {
        case TokenIconSize.BIG:
          return <ETHIcon32px />;
        case TokenIconSize.DEFAULT:
        default:
          return <ETHIcon24px />;
      }

    case KnownTokens.DAI:
      switch (size) {
        case TokenIconSize.BIG:
          return <DAIIcon32px />;
        case TokenIconSize.DEFAULT:
        default:
          return <DAIIcon24px />;
      }

    case KnownTokens.USDC:
      switch (size) {
        case TokenIconSize.BIG:
          return <USDCIcon32px />;
        case TokenIconSize.DEFAULT:
        default:
          return <USDCIcon24px />;
      }

    case KnownTokens.USDT:
      switch (size) {
        case TokenIconSize.BIG:
          return <USDTIcon32px />;
        case TokenIconSize.DEFAULT:
        default:
          return <USDTIcon24px />;
      }

    case KnownTokens.FIL:
      switch (size) {
        case TokenIconSize.BIG:
          return <FILIcon32px />;
        case TokenIconSize.DEFAULT:
        default:
          return <FILIcon24px />;
      }

    case KnownTokens.RENFIL:
      switch (size) {
        case TokenIconSize.BIG:
          return <RENFILIcon32px />;
        case TokenIconSize.DEFAULT:
        default:
          return <RENFILIcon24px />;
      }

    default:
      switch (size) {
        case TokenIconSize.BIG:
          return <CoinPlaceholder32px />;
        case TokenIconSize.DEFAULT:
        default:
          return <CoinPlaceholder24px />;
      }
  }
}
