export default {
  general: {
    startHere: 'Start Here',
    readMore: 'Read More',
    connectWallet: 'Connect wallet',
    disconnect: 'Disconnect',
    wallet: 'Wallet',
    ok: 'OK',
    dueDate: 'Due {{date}}',
    pause: 'Pause',
    edit: 'Edit',
    delete: 'Delete',
    approve: 'Approve',
    apply: 'Apply',
    close: 'Close',
    details: 'Details',
    saveChanges: 'Save changes',
    yesDelete: 'Yes, delete',
    cancel: 'Cancel',
    borrow: 'Borrow',
    republish: 'Republish',
    avg: 'AVG',
    completeAllFieldsToContinue: 'Complete all fields to continue',
    unknownError: 'An error has occurred. Please try again later.',
    navigation: {
      market: 'Market',
      borrow: 'Borrow',
      lend: 'Lend',
    },
    appStatus: {
      active: 'ACTIVE',
      published: 'PUBLISHED',
      approved: 'APPROVED',
      pending: 'PENDING',
      waitingToExecute: 'Waiting for Storage Provider to execute borrow',
      waitingForApprovalTx: 'Waiting for Approval Tx to be mined',
      waitingForBorrowTx: 'Waiting for Borrow Tx to be mined',
    },
    loanInfo: 'Loan info',
    viewApplicants: `Applicants <highlight>{{count}}</highlight>`,
    noClaimedMiners: 'You have no claimed miners',
    minerAlreadyClaimed: 'The miner id has already been claimed.',
    assetNotFound: 'Asset not found',
    couldntDisplayNewHealthFactorError:
      'Could’t display the new health factor correctly due to an error. ',
    assetValueInUsdError_one:
      'The USD estimated price is not correct due to an error. ',
    assetValueInUsdError_other:
      'The USD estimated price is not correct due to an error. ',
    underflowError:
      'Underflow error! The asset does not support this number of decimals. Maximum is {{count}} decimals!',
    underflowError2: 'The asset supports only {{count}} decimals!',
    creditScore: {
      na: 'N/A',
      poor: 'Poor',
      good: 'Good',
      excellent: 'Excellent',
    },
  },
};
