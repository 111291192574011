import React from 'react';

export function AddIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 8.49999L7.99998 8.5L7.99998 7.5L3 7.49999L3 8.49999ZM7.99998 8.5L13 8.49999L13 7.49999L7.99998 7.5L7.99998 8.5ZM8.49998 8V3H7.49998V8H8.49998ZM7.49998 8V13H8.49998V8H7.49998Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function AddIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 12.5H11.9999V11.5H4V12.5ZM11.9999 12.5L20 12.5L20 11.5L11.9999 11.5L11.9999 12.5ZM12.4999 12L12.5 4L11.5 4L11.4999 12L12.4999 12ZM11.4999 12V20H12.4999V12H11.4999Z"
        fill="currentColor"
      />
    </svg>
  );
}
