import { HealthKeys } from 'config';

function getHealthKey(healthValue: number): HealthKeys {
  if (healthValue <= 2) {
    return HealthKeys.RISKY;
  }
  if (healthValue <= 3) {
    return HealthKeys.OK;
  }
  return HealthKeys.GOOD;
}

export default getHealthKey;
