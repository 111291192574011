import { BigNumber, ethers } from 'ethers';

import { MinerResponsePropTypes } from 'api/requestWrappers/FilrepRequest';

const locale = 'en-US';

export function displayPrice(value: number) {
  return `${new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)} USD`;
}

export function displayNumber(
  value: number,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);
}

export function calcMinersPower(data: MinerResponsePropTypes[]): string {
  let total = BigNumber.from('0');

  data.forEach((item) => {
    total = total.add(item.size);
  });

  return total.toString();
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function valueInAssetUnits_to_BigNumber(
  valueInAssetUnits: string,
  assetDecimals = 18
): BigNumber {
  return ethers.utils.parseUnits(valueInAssetUnits, assetDecimals);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function wei_to_valueInAssetUnits(
  assetPriceInWei: string,
  assetDecimals = 18
): string {
  return ethers.utils.formatUnits(assetPriceInWei, assetDecimals);
}

export function displayValueInAssetUnits(valueInAssetUnits: string): string {
  if (
    valueInAssetUnits.split('.')[0] === '0' &&
    valueInAssetUnits.split('.')[1].slice(0, 7) === '0000000'
  ) {
    return (+valueInAssetUnits).toString();
  }

  return displayNumber(+valueInAssetUnits, 2, 7);
}

export function displayWeiAsValueInAssetUnits(
  assetPriceInWei: string,
  assetDecimals = 18
) {
  const valueInAssetUnits = wei_to_valueInAssetUnits(
    assetPriceInWei,
    assetDecimals
  );

  return displayValueInAssetUnits(valueInAssetUnits);
}

export function isValidNumber(input: string): boolean {
  return /^\d*\.?\d*$/.test(input);
}

export function isInvalidNumberFormat(input: string | undefined | null) {
  return (
    !input ||
    input === '.' ||
    input === '0.' ||
    (input.includes('.') && !input.split('.')[1])
  );
}
