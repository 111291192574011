import React from 'react';

export function SettingsIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99998 2.66666L12.6666 5.33332V10.6667L7.99998 13.3333L3.33331 10.6667V5.33332L7.99998 2.66666ZM7.99998 9.59999C8.89279 9.59999 9.61656 8.88364 9.61656 7.99999C9.61656 7.11633 8.89279 6.39999 7.99998 6.39999C7.10717 6.39999 6.3834 7.11633 6.3834 7.99999C6.3834 8.88364 7.10717 9.59999 7.99998 9.59999Z"
        stroke="black"
      />
      <path
        d="M9.61656 7.99999C9.61656 8.88364 8.89279 9.59999 7.99998 9.59999C7.10717 9.59999 6.3834 8.88364 6.3834 7.99999C6.3834 7.11633 7.10717 6.39999 7.99998 6.39999C8.89279 6.39999 9.61656 7.11633 9.61656 7.99999Z"
        stroke="black"
      />
    </svg>
  );
}

export function SettingsIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4L19 8V16L12 20L5 16V8L12 4ZM11.9999 14.4C13.3392 14.4 14.4248 13.3255 14.4248 12C14.4248 10.6745 13.3392 9.60001 11.9999 9.60001C10.6607 9.60001 9.57507 10.6745 9.57507 12C9.57507 13.3255 10.6607 14.4 11.9999 14.4Z"
        stroke="black"
      />
      <path
        d="M14.4248 12C14.4248 13.3255 13.3392 14.4 11.9999 14.4C10.6607 14.4 9.57507 13.3255 9.57507 12C9.57507 10.6745 10.6607 9.60001 11.9999 9.60001C13.3392 9.60001 14.4248 10.6745 14.4248 12Z"
        stroke="black"
      />
    </svg>
  );
}
