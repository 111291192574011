import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import GlobalStyle from './styles/global';
import reportWebVitals from './reportWebVitals';
import classic from './theme/classic';
import './translations/config';
import store from './redux/store';
import './redux/listeners';
import { NotificationSocketProvider } from './contexts/notificationSocket';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={classic}>
        <BrowserRouter>
          <GlobalStyle />
          <NotificationSocketProvider>
            <App />
          </NotificationSocketProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
