import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  height: 100%;
`;

export const Header = styled.h2`
  font-size: ${({ theme }) => theme.typography.pxToRem(4)};
  font-style: normal;
  font-weight: 600;
  line-height: ${({ theme }) => theme.typography.pxToRem(8)};
  letter-spacing: 0;
  text-align: center;
  margin: 0 0 48px;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.palette.text};
`;

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.palette.textSecondary};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 56px;

  > * + * {
    margin-left: 16px;
  }
`;
