import React from 'react';

export function USDTIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>USDT</title>
      <rect width="28" height="28" rx="4" fill="#50AF95" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8312 14.6008C15.7289 14.6082 15.2005 14.6387 14.0217 14.6387C13.0842 14.6387 12.4185 14.6115 12.185 14.6008C8.56182 14.447 5.85743 13.8384 5.85743 13.1096C5.85743 12.3809 8.56182 11.7731 12.185 11.6168V13.9947C12.4219 14.0111 13.1004 14.0498 14.0379 14.0498C15.163 14.0498 15.7264 14.0045 15.8278 13.9955V11.6185C19.4433 11.7739 22.1417 12.3826 22.1417 13.1096C22.1417 13.8367 19.4441 14.4454 15.8278 14.6L15.8312 14.6008ZM15.8312 11.3725V9.24474H20.8769V6H7.13931V9.24474H12.1841V11.3717C8.08367 11.5535 5 12.3373 5 13.2766C5 14.2159 8.08367 14.9989 12.1841 15.1815V22H15.8303V15.179C19.9214 14.9973 23 14.2143 23 13.2758C23 12.3373 19.924 11.5543 15.8303 11.3717L15.8312 11.3725Z"
        fill="white"
      />
    </svg>
  );
}

export function USDTIcon32px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>USDT</title>
      <rect width="28" height="28" rx="4" fill="#50AF95" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8312 14.6008C15.7289 14.6082 15.2005 14.6387 14.0217 14.6387C13.0842 14.6387 12.4185 14.6115 12.185 14.6008C8.56182 14.447 5.85743 13.8384 5.85743 13.1096C5.85743 12.3809 8.56182 11.7731 12.185 11.6168V13.9947C12.4219 14.0111 13.1004 14.0498 14.0379 14.0498C15.163 14.0498 15.7264 14.0045 15.8278 13.9955V11.6185C19.4433 11.7739 22.1417 12.3826 22.1417 13.1096C22.1417 13.8367 19.4441 14.4454 15.8278 14.6L15.8312 14.6008ZM15.8312 11.3725V9.24474H20.8769V6H7.13931V9.24474H12.1841V11.3717C8.08367 11.5535 5 12.3373 5 13.2766C5 14.2159 8.08367 14.9989 12.1841 15.1815V22H15.8303V15.179C19.9214 14.9973 23 14.2143 23 13.2758C23 12.3373 19.924 11.5543 15.8303 11.3717L15.8312 11.3725Z"
        fill="white"
      />
    </svg>
  );
}
