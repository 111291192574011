import React from 'react';

export function RedoIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 7L2.64645 6.64645L2.29289 7L2.64645 7.35355L3 7ZM10 6.5H3V7.5H10V6.5ZM3.35355 7.35355L7.35355 3.35355L6.64645 2.64645L2.64645 6.64645L3.35355 7.35355ZM2.64645 7.35355L6.64645 11.3536L7.35355 10.6464L3.35355 6.64645L2.64645 7.35355ZM12.5 10V14H13.5V10H12.5ZM10 7.5C11.3807 7.5 12.5 8.61929 12.5 10H13.5C13.5 8.067 11.933 6.5 10 6.5V7.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function RedoIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 10L5.64645 9.64645L5.29289 10L5.64645 10.3536L6 10ZM14 9.5H6V10.5H14V9.5ZM6.35355 10.3536L10.8536 5.85355L10.1464 5.14645L5.64645 9.64645L6.35355 10.3536ZM5.64645 10.3536L10.1464 14.8536L10.8536 14.1464L6.35355 9.64645L5.64645 10.3536ZM16.5 13V18H17.5V13H16.5ZM14 10.5C15.3807 10.5 16.5 11.6193 16.5 13H17.5C17.5 11.067 15.933 9.5 14 9.5V10.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
