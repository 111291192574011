import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import clsx from 'clsx';

import * as SC from './styled';

const tooltipRoot = document.querySelector('#root-tooltip');

type TooltipPropTypes = {
  children: React.ReactNode;
  text?: React.ReactNode;
  placement?: Placement;
  tabIndex?: number;
};

function Tooltip({ children, text, placement, tabIndex }: TooltipPropTypes) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [targetRef, setTargetRef] = useState();
  const [popperRef, setPopperRef] = useState();
  const [arrowRef, setArrowRef] = useState();

  const { styles, attributes, state } = usePopper(targetRef, popperRef, {
    placement,
    strategy: 'absolute',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'arrow', options: { element: arrowRef } },
      { name: 'preventOverflow', options: { padding: 4 } },
    ],
  });

  const handlerShow = () => {
    setShowTooltip(true);
  };

  const handlerHide = () => {
    setShowTooltip(false);
  };

  return (
    <>
      <SC.TargetWrapper
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2769
        ref={setTargetRef}
        className={clsx({
          target: Boolean(children),
          iconWrap: !children,
          active: showTooltip,
        })}
        tabIndex={tabIndex}
        onMouseOver={handlerShow}
        onMouseOut={handlerHide}
        onFocus={handlerShow}
        onBlur={handlerHide}
      >
        {children || <span>i</span>}
      </SC.TargetWrapper>

      {showTooltip &&
        ReactDOM.createPortal(
          <SC.Popper
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TS2769
            ref={setPopperRef}
            style={styles.popper}
            {...attributes.popper}
          >
            {text}
            <SC.Arrow
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore TS2769
              ref={setArrowRef}
              data-placement={state?.placement}
              style={styles.arrow}
            />
          </SC.Popper>,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TS2345
          tooltipRoot
        )}
    </>
  );
}

Tooltip.defaultProps = {
  placement: 'top',
  tabIndex: -1,
};

export default Tooltip;
