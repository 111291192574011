import React from 'react';

export function MailIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6667 12.6667C13.4031 12.6667 14.0001 12.0697 14.0001 11.3333C14.0001 10.597 13.4031 10 12.6667 10M12.6667 12.6667C11.9303 12.6667 11.3334 12.0697 11.3334 11.3333C11.3334 10.597 11.9303 10 12.6667 10M12.6667 12.6667V14M12.6667 10V8.66667M14.9761 10L13.8217 10.6667M11.5118 10.6666L10.3573 10M11.5118 12L10.3574 12.6667M14.9761 12.6666L13.8217 12M13.3334 7.33333V4.66667M8.66675 12H2.66675V4.66667M2.66675 4.66667V4H13.3334V4.66667M2.66675 4.66667L8.00008 8.66667L13.3334 4.66667"
        stroke="black"
      />
    </svg>
  );
}

export function MailIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 19C20.1045 19 21 18.1046 21 17C21 15.8954 20.1045 15 19 15M19 19C17.8954 19 17 18.1046 17 17C17 15.8954 17.8954 15 19 15M19 19V21M19 15V13M22.4641 15L20.7324 16M17.2676 15.9999L15.5359 15M17.2676 18L15.5359 19M22.4641 18.9999L20.7324 18M20 11V7M13 18H4V7M4 7V6H20V7M4 7L12 13L20 7"
        stroke="black"
      />
    </svg>
  );
}
