import { KnownTokens } from 'config';
import { MinersDropdownItemTypes } from 'components/dumb/MinersDropdown';

export enum LendCardStatus {
  Initial = 'Initial',
  Health = 'Health',
  Trust = 'Trust',
}

export interface CardProps {
  id?: number;
  borrowerId?: number;
  lenderId?: number;
  type?: 'Borrow' | 'Lend';
  status: LendCardStatus;
  assetName?: KnownTokens;
  assetAddress?: string;
  assetDecimals?: number;
  amount?: string | number;
  annualInterestRate?: string | number;
  maturityDate?: Date;
  className?: string;
}

export interface InitialCardProps extends CardProps {
  status: LendCardStatus.Initial;
}

export interface HealthCardProps {
  status: LendCardStatus.Health;
  currentHealthFactor: number;
  futureHealthFactor: number;
  className?: string;
}

export interface TrustCardProps {
  status: LendCardStatus.Trust;
  issuerName: string;
  issuerMinersText: string;
  issuerMiners: MinersDropdownItemTypes[];
  trustScore?: number | null;
  trustScoreIsAvg?: boolean;
  onSelectCard?(): void;
  style?: Record<string, string>;
  className?: string;
}

export type LendCardPropTypes =
  | InitialCardProps
  | HealthCardProps
  | TrustCardProps;
