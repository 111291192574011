import clsx from 'clsx';
import { Trans } from 'react-i18next';

import Trust, { TrustSize } from 'components/dumb/Trust';
import { TrustCardProps } from '../types';
import * as SC from '../styles';
import MinersDropdown from '../../../MinersDropdown';
import { ArrowRightIcon24px } from '../../../../icons/ArrowRightIcon';
import TypeChecker from '../../../../../helpers/classes/TypeChecker';

function TrustCard({
  status,
  issuerName,
  issuerMinersText,
  issuerMiners,
  trustScore,
  trustScoreIsAvg,
  className,
  onSelectCard,
  style,
}: TrustCardProps) {
  return (
    <SC.Wrapper className={clsx(className, status)} style={style}>
      <SC.Header>
        <Trust
          value={trustScore}
          isAvg={trustScoreIsAvg}
          size={TrustSize.BIG}
        />
        {TypeChecker.isFunction(onSelectCard) && (
          <SC.SelectApplicationIconButtonWrapper onClick={onSelectCard}>
            <ArrowRightIcon24px />
          </SC.SelectApplicationIconButtonWrapper>
        )}
      </SC.Header>
      <SC.Body>
        {issuerName}
        <span>{issuerMinersText}</span>
      </SC.Body>
      <SC.Footer className={status}>
        <MinersDropdown options={issuerMiners}>
          <Trans i18nKey="lend.minerInfo" />
        </MinersDropdown>
      </SC.Footer>
    </SC.Wrapper>
  );
}

export default TrustCard;
