// eslint-disable-next-line import/prefer-default-export
export enum TabKeys {
  MINERS = 'miners',
  LOAN_REQUESTS = 'loanRequests',
  ACTIVE_LOANS = 'activeLoans',
  CLOSED_LOANS = 'closedLoans',
}

export interface TabKeysStateProp {
  [TabKeys.MINERS]: number | null;
  [TabKeys.LOAN_REQUESTS]: number | null;
  [TabKeys.ACTIVE_LOANS]: number | null;
  [TabKeys.CLOSED_LOANS]: number | null;
}
