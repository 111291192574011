import { ReactElement } from 'react';

import * as SC from './styles';

interface ModalWrapperPropTypes {
  title?: string;
  subtitle?: string;
  children: ReactElement | ReactElement[] | string;
  footer?: ReactElement;
  [rest: string]: unknown;
}

function ModalWrapper({
  title,
  subtitle,
  children,
  footer,
  ...props
}: ModalWrapperPropTypes): ReactElement {
  return (
    <SC.Wrapper {...props}>
      <>
        {title || subtitle ? (
          <SC.Header>
            {title ? <SC.Title>{title}</SC.Title> : null}
            {subtitle ? <SC.Subtitle>{subtitle}</SC.Subtitle> : null}
          </SC.Header>
        ) : null}
        <SC.Body>{children}</SC.Body>
        {footer ? <SC.Footer>{footer}</SC.Footer> : null}
      </>
    </SC.Wrapper>
  );
}

export default ModalWrapper;
