import clsx from 'clsx';
import * as SC from './styled';

export enum TrustSize {
  DEFAULT = 'default',
  BIG = 'big',
}

export type TrustPropTypes = {
  value?: number | null;
  isAvg?: boolean | null;
  size?: TrustSize;
  className?: string;
};

function Trust({
  value,
  isAvg = false,
  size = TrustSize.DEFAULT,
  className,
}: TrustPropTypes) {
  return (
    <SC.Wrapper className={clsx(size, className)}>
      {value || '--'}
      {isAvg ? (
        <>
          {' '}
          <SC.Avg>AVG</SC.Avg>
        </>
      ) : null}
    </SC.Wrapper>
  );
}

export default Trust;
