import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

export const EVENTS = {
  API: {
    UNAUTHORIZED_ACCESS_REQUEST: 'api/unauthorizedAccessRequest',
  },
  WS: {
    NOTIFICATION: {
      RECEIVED: 'ws/notification/received',
      MARKET_REQUEST_PUBLISHED: 'ws/notification/marketRequestPublished',
      MARKET_REQUEST_APPLIED: 'ws/notification/marketRequestApplied',
      MARKET_REQUEST_APPROVED: 'ws/notification/marketRequestApproved',
      BORROW_EXECUTED: 'ws/notification/borrowExecuted',
      PRINCIPAL_PAYMENT: 'ws/notification/delegatorPrincipalPayment',
      LOAN_MATURITY_1_DAY_REMINDER: 'ws/notification/loanMaturity1DayReminder',
      LOAN_MATURITY_7_DAYS_REMINDER:
        'ws/notification/loanMaturity7DaysReminder',
      LOAN_MATURITY_1_MONTH_REMINDER:
        'ws/notification/loanMaturity1MonthReminder',
      MONTHLY_RATE_5_DAYS_REMINDER: 'ws/notification/monthlyRate5DayReminder',
      MONTHLY_RATE_PAYMENT: 'ws/notification/monthlyRatePayment',
      OVERDUE_LOAN_PAYMENT: 'ws/notification/overdueLoanPayment',
      OVERDUE_MONTHLY_RATE_PAYMENT: 'ws/notification/overdueMonthlyRatePayment',
    },
  },
};

export default eventEmitter;
