import styled, { keyframes } from 'styled-components';

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% { 
    transform: rotate(360deg); 
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.sets.green.main};
  animation: ${rotateAnimation} 5s linear infinite;
`;
