import React from 'react';

export function RENFILIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>RENFIL</title>
      <g clipPath="url(#clip0_2259_1114)">
        <rect width="28" height="28" rx="4" fill="#B3BDC7" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.8198 -12.2929L22.1127 -13L-9 18.1127L-8.29289 18.8198L22.8198 -12.2929ZM24.234 -10.8787L23.5269 -11.5858L-7.58582 19.5269L-6.87871 20.234L24.234 -10.8787ZM24.9412 -10.1716L25.6483 -9.46447L-5.4644 21.6482L-6.17151 20.9411L24.9412 -10.1716ZM27.0625 -8.05025L26.3554 -8.75736L-4.75732 22.3553L-4.05022 23.0624L27.0625 -8.05025ZM27.7696 -7.34315L28.4767 -6.63604L-2.63603 24.4767L-3.34314 23.7696L27.7696 -7.34315ZM29.8909 -5.22183L29.1837 -5.92893L-1.92896 25.1838L-1.22185 25.8909L29.8909 -5.22183ZM30.598 -4.51472L31.3052 -3.80761L0.192458 27.3051L-0.514648 26.598L30.598 -4.51472ZM32.7193 -2.3934L32.0122 -3.1005L0.899536 28.0122L1.60664 28.7193L32.7193 -2.3934ZM33.4264 -1.68629L34.1335 -0.979184L3.02083 30.1335L2.31372 29.4264L33.4264 -1.68629ZM35.5477 0.435029L34.8406 -0.272078L3.72791 30.8406L4.43501 31.5477L35.5477 0.435029ZM36.2549 1.14214L36.962 1.84924L5.84932 32.9619L5.14221 32.2548L36.2549 1.14214ZM38.3762 3.26346L37.6691 2.55635L6.5564 33.669L7.2635 34.3762L38.3762 3.26346ZM39.0833 3.97056L39.7904 4.67767L8.67769 35.7904L7.97058 35.0833L39.0833 3.97056ZM40.9914 5.87868L40.2843 5.17157L9.1716 36.2843L9.87871 36.9914L40.9914 5.87868Z"
          fill="white"
        />
        <rect x="2.5" y="2.5" width="23" height="23" rx="11.5" fill="#0090FF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.045 12.68L14.715 14.44L17.85 14.88L17.63 15.705L14.55 15.265C14.33 15.98 14.22 16.75 13.945 17.41C13.67 18.18 13.395 18.95 13.065 19.665C12.625 20.6 11.855 21.26 10.81 21.425C10.205 21.535 9.54497 21.48 9.04997 21.095C8.88497 20.985 8.71997 20.765 8.71997 20.6C8.71997 20.38 8.82997 20.105 8.99497 19.995C9.10497 19.94 9.37997 19.995 9.54497 20.05C9.70997 20.215 9.87497 20.435 9.98497 20.655C10.315 21.095 10.755 21.15 11.195 20.82C11.69 20.38 11.965 19.775 12.13 19.17C12.46 17.85 12.79 16.585 13.065 15.265V15.045L10.15 14.605L10.26 13.78L13.285 14.22L13.67 12.515L10.535 12.02L10.645 11.14L13.89 11.58C14 11.25 14.055 10.975 14.165 10.7C14.44 9.71 14.715 8.72 15.375 7.84C16.035 6.96 16.805 6.41 17.96 6.465C18.455 6.465 18.95 6.63 19.28 7.015C19.335 7.07 19.445 7.18 19.445 7.29C19.445 7.51 19.445 7.785 19.28 7.95C19.06 8.115 18.785 8.06 18.565 7.84C18.4 7.675 18.29 7.51 18.125 7.345C17.795 6.905 17.3 6.85 16.915 7.235C16.64 7.51 16.365 7.895 16.2 8.28C15.815 9.435 15.54 10.645 15.155 11.855L18.18 12.295L17.96 13.12L15.045 12.68Z"
          fill="white"
        />
        <rect x="2.5" y="2.5" width="23" height="23" rx="11.5" stroke="white" />
      </g>
      <defs>
        <clipPath id="clip0_2259_1114">
          <rect width="28" height="28" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function RENFILIcon32px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>RENFIL</title>
      <g clipPath="url(#clip0_2259_1114)">
        <rect width="28" height="28" rx="4" fill="#B3BDC7" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.8198 -12.2929L22.1127 -13L-9 18.1127L-8.29289 18.8198L22.8198 -12.2929ZM24.234 -10.8787L23.5269 -11.5858L-7.58582 19.5269L-6.87871 20.234L24.234 -10.8787ZM24.9412 -10.1716L25.6483 -9.46447L-5.4644 21.6482L-6.17151 20.9411L24.9412 -10.1716ZM27.0625 -8.05025L26.3554 -8.75736L-4.75732 22.3553L-4.05022 23.0624L27.0625 -8.05025ZM27.7696 -7.34315L28.4767 -6.63604L-2.63603 24.4767L-3.34314 23.7696L27.7696 -7.34315ZM29.8909 -5.22183L29.1837 -5.92893L-1.92896 25.1838L-1.22185 25.8909L29.8909 -5.22183ZM30.598 -4.51472L31.3052 -3.80761L0.192458 27.3051L-0.514648 26.598L30.598 -4.51472ZM32.7193 -2.3934L32.0122 -3.1005L0.899536 28.0122L1.60664 28.7193L32.7193 -2.3934ZM33.4264 -1.68629L34.1335 -0.979184L3.02083 30.1335L2.31372 29.4264L33.4264 -1.68629ZM35.5477 0.435029L34.8406 -0.272078L3.72791 30.8406L4.43501 31.5477L35.5477 0.435029ZM36.2549 1.14214L36.962 1.84924L5.84932 32.9619L5.14221 32.2548L36.2549 1.14214ZM38.3762 3.26346L37.6691 2.55635L6.5564 33.669L7.2635 34.3762L38.3762 3.26346ZM39.0833 3.97056L39.7904 4.67767L8.67769 35.7904L7.97058 35.0833L39.0833 3.97056ZM40.9914 5.87868L40.2843 5.17157L9.1716 36.2843L9.87871 36.9914L40.9914 5.87868Z"
          fill="white"
        />
        <rect x="2.5" y="2.5" width="23" height="23" rx="11.5" fill="#0090FF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.045 12.68L14.715 14.44L17.85 14.88L17.63 15.705L14.55 15.265C14.33 15.98 14.22 16.75 13.945 17.41C13.67 18.18 13.395 18.95 13.065 19.665C12.625 20.6 11.855 21.26 10.81 21.425C10.205 21.535 9.54497 21.48 9.04997 21.095C8.88497 20.985 8.71997 20.765 8.71997 20.6C8.71997 20.38 8.82997 20.105 8.99497 19.995C9.10497 19.94 9.37997 19.995 9.54497 20.05C9.70997 20.215 9.87497 20.435 9.98497 20.655C10.315 21.095 10.755 21.15 11.195 20.82C11.69 20.38 11.965 19.775 12.13 19.17C12.46 17.85 12.79 16.585 13.065 15.265V15.045L10.15 14.605L10.26 13.78L13.285 14.22L13.67 12.515L10.535 12.02L10.645 11.14L13.89 11.58C14 11.25 14.055 10.975 14.165 10.7C14.44 9.71 14.715 8.72 15.375 7.84C16.035 6.96 16.805 6.41 17.96 6.465C18.455 6.465 18.95 6.63 19.28 7.015C19.335 7.07 19.445 7.18 19.445 7.29C19.445 7.51 19.445 7.785 19.28 7.95C19.06 8.115 18.785 8.06 18.565 7.84C18.4 7.675 18.29 7.51 18.125 7.345C17.795 6.905 17.3 6.85 16.915 7.235C16.64 7.51 16.365 7.895 16.2 8.28C15.815 9.435 15.54 10.645 15.155 11.855L18.18 12.295L17.96 13.12L15.045 12.68Z"
          fill="white"
        />
        <rect x="2.5" y="2.5" width="23" height="23" rx="11.5" stroke="white" />
      </g>
      <defs>
        <clipPath id="clip0_2259_1114">
          <rect width="28" height="28" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
