import { BigNumber, Contract, ethers } from 'ethers';
import { useTranslation } from 'react-i18next';
import { lendingPoolV2Address } from '../../constants/contracts';
import lendingPoolV2Abi from '../../abis/lendingPoolV2';
import useGetAssetValueInEthValue from '../../hooks/useGetAssetValueInEthValue';
import { Asset } from '../../../api/requestWrappers/AssetsRequest';
import { HEALTH_FACTOR_MULTIPLIER } from '../constants';
import toastAlert from '../../../alerts/ToastAlert';

const { Web3Provider } = ethers.providers;
const { ethereum } = window;

export const getDisplayableHealthFactor = (
  healthFactor: BigNumber,
  fractionDigits = 2
) => {
  const formattedHealthFactor = ethers.utils.formatEther(healthFactor);
  if (formattedHealthFactor.split('.')[0].length > 10) {
    return 0;
  }
  if (fractionDigits < 1) {
    return +formattedHealthFactor;
  }

  return +(+formattedHealthFactor).toFixed(fractionDigits);
};

interface FutureHealthFactorConfig {
  asset: Asset;
  amountInWei: string;
}

interface HealthFactors {
  currentHealthFactor: number;
  futureHealthFactor: number;
}

const useGetHealthFactorOfAddress = (fractionDigits = 2) => {
  const { getAssetValueInEthValue } = useGetAssetValueInEthValue();
  const { t } = useTranslation();

  const getHealthFactorOfAddress = async (
    address: string,
    futureHealthFactorConfig?: FutureHealthFactorConfig
  ): Promise<number | HealthFactors> => {
    if (!ethereum?.isConnected() || !address) {
      return 0;
    }
    const provider = new Web3Provider(ethereum);
    const aaveLendingPoolV2Contract = new Contract(
      lendingPoolV2Address,
      lendingPoolV2Abi,
      provider
    );
    const userData = await aaveLendingPoolV2Contract.getUserAccountData(
      address
    );
    const {
      healthFactor,
      totalCollateralETH,
      totalDebtETH,
      currentLiquidationThreshold,
    } = userData;
    const currentHealthFactor = getDisplayableHealthFactor(
      healthFactor,
      fractionDigits
    );

    let futureHealthFactor = 0;
    if (futureHealthFactorConfig) {
      const assetValueInEthValue = await getAssetValueInEthValue(
        futureHealthFactorConfig.asset
      );
      if (!assetValueInEthValue) {
        toastAlert.showError(t('general.couldntDisplayNewHealthFactorError'));
        return { currentHealthFactor, futureHealthFactor };
      }
      const newDebt = BigNumber.from(futureHealthFactorConfig.amountInWei);
      const newDebtInEth = assetValueInEthValue
        .mul(newDebt)
        .div(BigNumber.from(10).pow(futureHealthFactorConfig.asset.decimals));

      const computedHealthFactor = totalCollateralETH
        .mul(currentLiquidationThreshold)
        .mul(HEALTH_FACTOR_MULTIPLIER)
        .div(totalDebtETH.add(newDebtInEth));
      futureHealthFactor = getDisplayableHealthFactor(
        computedHealthFactor,
        fractionDigits
      );

      return { currentHealthFactor, futureHealthFactor };
    }

    return currentHealthFactor;
  };

  return { getHealthFactorOfAddress };
};

export default useGetHealthFactorOfAddress;
