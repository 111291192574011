import styled from 'styled-components';

type Props = {
  percent: number;
};

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div<Props>`
  height: 6px;
  background: linear-gradient(
    90deg,
    #2ab952 9.97%,
    #d9cb53 31.35%,
    #fdcc4e 49.92%,
    #faa33d 69.26%,
    #f7472c 88.24%
  );
  border-radius: 1px;
  position: relative;

  &::after {
    content: '';
    display: block;
    background: ${({ theme }) => theme.palette.raw.black};
    border: 2px solid ${({ theme }) => theme.palette.raw.white};
    position: absolute;
    width: 2px;
    height: 12px;
    right: ${({ percent }) => `${percent}%`};
    top: 50%;
    transform: translate(100%, -50%);
    transform-origin: center;
    box-sizing: content-box;
    border-radius: 1px;
  }
`;
