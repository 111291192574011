import { useAppSelector } from '../../hooks/redux';
import { supportedAssetsSelector } from '../../redux/slices/selectors';
import { Asset } from '../../api/requestWrappers/AssetsRequest';

const useFindAssetInfo = () => {
  const supportedAssets = useAppSelector(supportedAssetsSelector);

  const supportedAssetsFetched = Boolean(supportedAssets.length);

  const findAssetInfo = (assetAddress: string): Asset | undefined =>
    supportedAssets.find(
      (supportedAsset) =>
        supportedAsset.address.toLowerCase() === assetAddress.toLowerCase()
    );
  return { findAssetInfo, supportedAssetsFetched };
};

export default useFindAssetInfo;
