import general from './general/en';
import metamask from './metamask/en';
import market from './market/en';
import borrow from './borrow/en';
import lend from './lend/en';
import notifications from './notifications/en';

const en = {
  translations: {
    ...general,
    ...metamask,
    ...market,
    ...borrow,
    ...lend,
    ...notifications,
  },
};

export default en;
