import { ReactElement, ReactNode } from 'react';
import Ripple from 'material-ripple-effects';
import { AddIcon24px } from 'components/icons/AddIcon';
import SC from './styles';

interface Props {
  onClick?(): void;
  isDisabled?: boolean;
  [rest: string]: unknown;
  children: ReactNode;
  icon?: ReactElement;
}

export default function CardButton({
  onClick,
  isDisabled,
  children,
  icon,
  ...rest
}: Props): ReactElement {
  const ripple = new Ripple();

  return (
    <SC.Container
      isDisabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      onMouseUp={isDisabled ? undefined : (e) => ripple.create(e, 'dark')}
      {...rest}
    >
      <SC.Icon isDisabled={isDisabled}>{icon || <AddIcon24px />}</SC.Icon>
      <SC.Text isDisabled={isDisabled}>{children}</SC.Text>
    </SC.Container>
  );
}

CardButton.defaultProps = {
  isDisabled: false,
  icon: null,
};
