import AbstractClientStore from './AbstractClientStore';

const LOCAL_STORAGE_KEY = 'enableTokenTxHashes';

interface TokenTxHash {
  tokenAddress: string;
  txHash: string;
}

const lowerCaseFields = (data: TokenTxHash): TokenTxHash => ({
  tokenAddress: data.tokenAddress.toLowerCase(),
  txHash: data.txHash.toLowerCase(),
});

class EnableTokensClientStore extends AbstractClientStore {
  static getTokenTxHashes(): TokenTxHash[] {
    const lsItem = this.storage.getItem(LOCAL_STORAGE_KEY);
    if (!lsItem) {
      return [];
    }

    return JSON.parse(lsItem);
  }

  static addTokenTxHash(data: TokenTxHash): void {
    const lsItem = this.storage.getItem(LOCAL_STORAGE_KEY);
    if (lsItem) {
      const tokens: TokenTxHash[] = JSON.parse(lsItem);
      if (
        !tokens.some(
          (token) => token.tokenAddress === data.tokenAddress.toLowerCase()
        )
      ) {
        tokens.push(lowerCaseFields(data));
        this.storage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(tokens));
      }
    } else {
      this.storage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify([lowerCaseFields(data)])
      );
    }
  }

  static findTokenTxHash(tokenAddress: string): TokenTxHash | undefined {
    return this.getTokenTxHashes().find(
      (token) => token.tokenAddress === tokenAddress.toLowerCase()
    );
  }

  static clear(tokenAddress: string | undefined = undefined): void {
    if (tokenAddress === undefined) {
      this.storage.removeItem(LOCAL_STORAGE_KEY);
      return;
    }

    const lsItem = this.storage.getItem(LOCAL_STORAGE_KEY);
    if (!lsItem) {
      this.storage.removeItem(LOCAL_STORAGE_KEY);
      return;
    }

    let tokens: TokenTxHash[] = JSON.parse(lsItem);
    tokens = tokens.filter(
      (token) => token.tokenAddress !== tokenAddress.toLowerCase()
    );

    if (!tokens.length) {
      this.storage.removeItem(LOCAL_STORAGE_KEY);
      return;
    }

    this.storage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(tokens));
  }
}

export default EnableTokensClientStore;
