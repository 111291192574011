export default {
  borrow: {
    borrowedBalance: 'Borrowed balance',
    maturityDate: 'Maturity date',
    loanDuration: 'Loan duration',
    aaveVariableInterestRate: 'AAve variable IR',
    monthlyRate: 'Monthly rate',
    apr: 'Annual interest rate',
    currentlyOwed: 'Currently owed',
    repay: 'Repay',
    repaidSuccessfullyMessage:
      'Your repayment was successful. Wait a few seconds and refresh the page to check the status of your repayment.',
    createLoanRequest: 'Create loan request',
    createALoanRequest: 'Create a loan request',
    assetType: 'Asset type',
    amount: 'Amount',
    enterAmount: 'Enter amount ({{crypto}})',
    usdEstimate: 'Usd estimate',
    annualInterestRate: 'Annual interest rate',
    publishYourRequest: 'Publish your request',
    minersTitle: `My miners <light>{{minersCount}}</light>`,
    minersParagraph:
      'Before you can operate a loan request or apply for a credit delegation, you have to prove that you are the owner of a miner.',
    loansTitle: `My loans <light>{{loansCount}}</light>`,
    loansParagraph:
      'After claiming your miner you will be able to borrow funds by creating loan requests or applying to an offer from the market.',
    claimYourMiners: 'Claim your miners',
    activeLoan: 'Active loan',
    executeBorrow: 'Execute borrow',
    approvedLoanRequest: 'Approved loan request ...',
    claimMiner: {
      step1: {
        title: 'Step 1 of 2',
        subtitle: 'Enter your miner details',
        confirmButton: 'Send instructions',
        emailInputLabel: 'E-MAIL ADDRESS',
        emailInputPlaceholder: 'example@mail.com',
        minerInputLabel: 'MINER ID',
        minerInputPlaceholder: 'f00000',
        companyInputLabel: 'COMPANY NAME',
        companyInputPlaceholder: 'Company',
        addressInputLabel: 'MINER ADDRESS',
        addressInputPlaceholder: 'Address',
        hint: 'Instructions on how to claim your miner will be sent to this e-mail  address.',
      },
      step2: {
        title: 'Step 2 of 2',
        subtitle: 'Enter miner unique signature',
        confirmButton: 'Send instructions',
        signatureInputPlaceholder: 'Copy and paste the miner signature here',
      },
      step3: {
        title:
          '🎉<br />Congratulations!<br/>You’ve successfully claimed your miner.',
        subtitle:
          'Now you can borrow funds by creating loan requests or applying for credit delegation offers.',
      },
    },
    minerReputationScore: 'Miner reputation score',
    loan: {
      information: {
        title: 'Loan information',
        paidMaturity: 'To be paid at maturity',
        repayButton: 'Repay now',
        description:
          'Optionally, you can make partial payments or repay in full at any time.',
        principal: 'Principal',
        accruedInterest: 'Accrued interest',
        variableRate: 'AAve variable rate',
        maturityDate: 'Maturity date',
        totalLoan: 'Total loan amount <highlight>{{amount}}</highlight>',
      },
      payments: {
        title: 'Monthly payments',
        totalBalanceDue: 'Total balance due',
        paidMaturity: 'To be paid at maturity',
        repayButton: 'Repay now',
        description:
          'Missing your monthly payments will result in a lower credit score.',
        monthlyRate: 'Monthly rate',
        apr: 'Annual interest rate',
        overduePayment: 'Overdue payments <highlight>{{amount}}</highlight>',
        showPayments: 'Show',
        hidePayments: 'Hide',
        monthlyRateMaturityDate: 'Monthly rate maturity date',
        amount: 'Amount',
        paymentHistory: 'Payment history',
      },
      repay: {
        itself: 'Repay',
        enterAmountToRepay: 'Enter amount to repay',
        enterDestinationAndAmountToRepay:
          'Enter destination and amount to repay',
        aaveAmountToBePaid: 'Amount to be paid to the AAve',
        delegatorAmountToBePaid: 'Amount to be paid to the delegator',
        amountDescription: '',
        enableToken: 'Enable token',
        enablingToken: 'Enabling token',
        tokenEnableSuccess:
          'Token has been enabled. You can now repay your loan.',
        tokenEnableError:
          'An error has occurred while enabling the token. Try again.',
      },
    },
    myMiners: 'My miners',
    myLoanRequests: 'My loan requests/applications',
    activeLoans: 'Active loans',
    closedLoans: 'Closed loans',
    loanRequestsPage: {
      asset: 'ASSET',
      annualIR: 'ANNUAL IR',
      type: 'TYPE',
      appDate: 'APPLICATION DATE',
      maturityDate: 'MATURITY DATE',
      duration: 'DURATION',
      status: 'STATUS',
      action: 'ACTION',
      published: 'PUBLISHED',
      applied: 'APPLIED',
      accepted: 'ACCEPTED',
      rejected: 'REJECTED',
      request: 'Request',
      application: 'Application',
    },
    activeLoansPage: {
      originalLoanAmount: 'Original loan amount',
      currentLoanAmount: 'Current loan amount',
      asset: 'ASSET',
      annualIR: 'ANNUAL IR',
      type: 'TYPE',
      maturityDate: 'MATURITY DATE',
      duration: 'duration',
      balanceDue: 'BALANCE DUE',
      status: 'STATUS',
      action: 'ACTION',
      statusOk: 'OK',
      statusOverdue: 'OVERDUE',
      publishedOn: 'Published on',
      approval: 'Approval',
      offer: 'Offer',
    },
    closedLoansPage: {
      statusClosed: 'CLOSED',
    },
    editLoanRequest: 'Edit loan request',
  },
};
