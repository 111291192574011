import TypeChecker from 'helpers/classes/TypeChecker';

// eslint-disable-next-line import/prefer-default-export
export function getExistingValues(obj: Record<any, any>): Record<any, any> {
  const existingValuesObj: Record<any, any> = {};
  Object.keys(obj).forEach((key) => {
    if ((obj[key] || TypeChecker.isBoolean(obj[key])) && obj[key] !== 0) {
      existingValuesObj[key] = obj[key];
    }
  });

  return existingValuesObj;
}
