import eventEmitter, { EVENTS } from 'eventEmitter';
import store from '../store';
import { logout } from '../slices/auth';
import { setUnread } from '../slices/notifications';
import { unreadNotificationsSelector } from '../slices/selectors';

eventEmitter.on(EVENTS.API.UNAUTHORIZED_ACCESS_REQUEST, () => {
  store.dispatch(logout());
});

eventEmitter.on(EVENTS.WS.NOTIFICATION.RECEIVED, () => {
  store.dispatch(setUnread(unreadNotificationsSelector(store.getState()) + 1));
});
