import React from 'react';

export function MenuIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 7.99999H13M3 5H13M3 11H13"
        stroke="black"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function MenuIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 12H20M4 7H20M4 17H20" stroke="black" strokeLinejoin="round" />
    </svg>
  );
}
