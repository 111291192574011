import { lazy } from 'react';
import { Route } from './types';
import PATH from './paths';

const ReadMore = lazy(() => import('components/dumb/ReadMore'));

const READ_MORE_INDEX: Route = {
  component: ReadMore,
  path: PATH.READ_MORE.INDEX,
  isExact: true,
  isProtected: false,
};

export default [READ_MORE_INDEX];
