import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const Market = lazy(() => import('components/smart/containers/Market'));
const EditMarketItem = lazy(
  () => import('components/smart/containers/Market/EditMarketItem')
);

const MARKET_INDEX: Route = {
  component: Market,
  path: [PATH.INDEX, PATH.MARKET.INDEX],
  isExact: true,
  isProtected: false,
};

const MARKET_EDIT_ITEM: Route = {
  component: EditMarketItem,
  path: PATH.MARKET.EDIT_MARKET_ITEM,
  isExact: true,
  isProtected: true,
};

export default [MARKET_EDIT_ITEM, MARKET_INDEX];
