import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const Lend = lazy(() => import('components/smart/containers/Lend'));
const Loan = lazy(() => import('components/smart/containers/Lend/Loan'));
const Approve = lazy(() => import('components/smart/containers/Lend/Approve'));
const CreateDelegationOffer = lazy(
  () => import('components/smart/containers/Lend/CreateDelegationOffer')
);

const LEND_INDEX: Route = {
  component: Lend,
  path: PATH.LEND.INDEX,
  isExact: true,
  isProtected: false,
};

const LEND_LOAN: Route = {
  component: Loan,
  path: PATH.LEND.LOAN,
  isExact: true,
  isProtected: true,
};

const LEND_APPROVE: Route = {
  component: Approve,
  path: PATH.LEND.APPROVE,
  isExact: true,
  isProtected: true,
};

const LEND_CREATE_DELEGATION_OFFER: Route = {
  component: CreateDelegationOffer,
  path: PATH.LEND.CREATE_DELEGATION_OFFER,
  isExact: true,
  isProtected: true,
};

export default [
  LEND_APPROVE,
  LEND_LOAN,
  LEND_CREATE_DELEGATION_OFFER,
  LEND_INDEX,
];
