import React from 'react';

export function ETHIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 32 32"
      {...props}
    >
      <title>ETH</title>
      <rect x="0" y="0" width="32" height="32" rx="16%" fill="#63698E" />
      <g fill="#fff" fillRule="nonzero">
        <path fillOpacity="0.5" d="M16.498 4L9 16.22l7.498-3.35z" />
        <path fillOpacity="0.75" d="M16.498 4v8.87l7.497 3.35z" />
        <path fillOpacity="0.8" d="M9 16.22l7.498 4.353v-7.701z" />
        <path fillOpacity="0.9" d="M16.498 20.573l7.497-4.353-7.497-3.348z" />
        <path fillOpacity="0.6" d="M16.498 27.995v-6.028L9 17.616z" />
        <path fillOpacity="0.7" d="M16.498 21.968v6.027L24 17.616z" />
      </g>
    </svg>
  );
}

export function ETHIcon28px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="28" height="28" rx="4" fill="#62688F" />
      <path d="M13.9978 4L8 14.1854L13.9978 11.3957V4Z" fill="#C0C2D1" />
      <path
        d="M13.9978 11.3957L8 14.1854L13.9978 17.8144V11.3957Z"
        fill="#F2F2F6"
      />
      <path
        d="M19.9967 14.1854L13.9978 4V11.3957L19.9967 14.1854Z"
        fill="#EFF0F4"
      />
      <path
        d="M13.9978 17.8145L19.9967 14.1855L13.9978 11.3958V17.8145Z"
        fill="white"
      />
      <path d="M8 15.3499L13.9978 24V18.9767L8 15.3499Z" fill="#C0C2D1" />
      <path d="M13.9978 18.9767V24L20 15.35L13.9978 18.9767Z" fill="#DFE0E8" />
    </svg>
  );
}

export function ETHIcon32px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      {...props}
    >
      <title>ETH</title>
      <rect x="0" y="0" width="32" height="32" rx="16%" fill="#63698E" />
      <g fill="#fff" fillRule="nonzero">
        <path fillOpacity="0.5" d="M16.498 4L9 16.22l7.498-3.35z" />
        <path fillOpacity="0.75" d="M16.498 4v8.87l7.497 3.35z" />
        <path fillOpacity="0.8" d="M9 16.22l7.498 4.353v-7.701z" />
        <path fillOpacity="0.9" d="M16.498 20.573l7.497-4.353-7.497-3.348z" />
        <path fillOpacity="0.6" d="M16.498 27.995v-6.028L9 17.616z" />
        <path fillOpacity="0.7" d="M16.498 21.968v6.027L24 17.616z" />
      </g>
    </svg>
  );
}
