import { getExistingValues } from 'helpers/functions/objects';
import AbstractRequest, { QueryParam } from './AbstractRequest';
import { ApiMethod } from './constants';
import { KnownTokens } from '../../config';
import { ApplicationOfferApiResponse } from './types/general';

export enum MarketRequestStatus {
  Initial = 'Initial',
  WaitingForApprovalTx = 'WaitingForApproveTx',
  ApprovedByLender = 'ApprovedByLender',
  WaitingForBorrowTx = 'WaitingForBorrowTx',
  BorrowExecuted = 'BorrowExecuted',
}

interface CreateBorrowRequestData {
  assetId: number;
  amount: string;
  annualInterestRate: number;
  durationInMonths: number;
  type: 'lend' | 'borrow';
}

interface GetMarketRequestsData {
  type: 'lend' | 'borrow';
  limit: number;
  offset: number;
  status?: MarketRequestStatus;
}

interface ApproveDelegationRequestData {
  approvalTxHash: string;
  applicantId?: number;
}

interface ExecuteBorrowRequestData {
  requestId: number;
  borrowTxHash: string;
}

export interface BorrowIssuerMinersApiResponse {
  minerId: string;
  status: 'CONFIRMED' | string;
  companyName: string;
}

export enum CreditScoreEnum {
  Poor = 'Poor',
  Good = 'Good',
  Excellent = 'Excellent',
  NA = 'NA',
}

export interface BorrowIssuerApiResponse {
  id: number;
  address: string;
  miners: BorrowIssuerMinersApiResponse[];
  creditScore: CreditScoreEnum;
}

export interface LenderIssuerApiResponse {
  id: number;
  address: string;
}

export enum MarketRequestTypeApiResponse {
  LEND = 'Lend',
  BORROW = 'Borrow',
}

export interface MarketRequestApiResponse {
  amount: string;
  annualInterestRate: string;
  assetAddress: string;
  assetDecimals: number;
  assetName: KnownTokens;
  borrowerId?: number;
  borrower?: BorrowIssuerApiResponse;
  createdAt: string;
  id: number;
  lenderId?: number;
  lender?: LenderIssuerApiResponse;
  dueDate: Date;
  durationInMonths: number;
  status: MarketRequestStatus;
  type: MarketRequestTypeApiResponse;
  updatedAt: string;
  applicationsCount?: number;
  userApplied?: boolean;
}

export interface GetApplicationForOfferApiResponse {
  applications: ApplicationOfferApiResponse[];
}

export interface PatchMarketRequest {
  assetId?: string | number;
  amount?: string;
  annualInterestRate?: string | number;
  durationInMonths?: number;
}

export class MarketRequest extends AbstractRequest {
  get apiResource(): string {
    return 'market';
  }

  get dynamicIds(): Record<string, string> {
    return {
      REQUEST_ID: '{requestId}',
    };
  }

  get routes(): Record<string, string> {
    return {
      CREATE_BORROW_REQUEST: `${this.apiRoute}/requests`,
      GET_MARKET_REQUESTS: `${this.apiRoute}/requests`,
      FIND_MARKET_REQUEST: `${this.apiRoute}/requests/${this.dynamicIds.REQUEST_ID}`,
      PATCH_MARKET_REQUEST: `${this.apiRoute}/requests/${this.dynamicIds.REQUEST_ID}`,
      DELETE_MARKET_REQUEST: `${this.apiRoute}/requests/${this.dynamicIds.REQUEST_ID}`,
      APPROVE_DELEGATION: `${this.apiRoute}/requests/${this.dynamicIds.REQUEST_ID}/approve`,
      EXECUTE_BORROW: `${this.apiRoute}/requests/${this.dynamicIds.REQUEST_ID}/borrow`,
      GET_MY_MARKET_REQUESTS: `${this.apiRoute}/my-requests`,
      APPLY_TO_OFFER: `${this.apiRoute}/requests/${this.dynamicIds.REQUEST_ID}/apply`,
      GET_APPLICATIONS_FOR_OFFER: `${this.apiRoute}/requests/${this.dynamicIds.REQUEST_ID}/applications`,
    };
  }

  async createBorrowRequest(data: CreateBorrowRequestData) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.CREATE_BORROW_REQUEST,
      data
    );

    return result?.data;
  }

  async getMarketRequests(data: GetMarketRequestsData) {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_MARKET_REQUESTS +
        this.buildQueryString(this.buildPaginationQueryParams(data))
    );

    return result?.data;
  }

  async getMyMarketRequests(data: GetMarketRequestsData) {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_MY_MARKET_REQUESTS +
        this.buildQueryString(this.buildPaginationQueryParams(data))
    );

    return result?.data;
  }

  async findMarketRequest(requestId: number, queryParams: QueryParam[] = []) {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.FIND_MARKET_REQUEST.replace(
        this.dynamicIds.REQUEST_ID,
        requestId.toString()
      ) + this.buildQueryParams(queryParams)
    );

    return result?.data;
  }

  async patchMarketRequest(
    requestId: number | string,
    data: PatchMarketRequest
  ) {
    const result = await this.request(
      ApiMethod.PATCH,
      this.routes.PATCH_MARKET_REQUEST.replace(
        this.dynamicIds.REQUEST_ID,
        requestId.toString()
      ),
      getExistingValues({
        ...data,
        maturityDate: Number(data.durationInMonths),
        annualInterestRate: Number(data.annualInterestRate),
      })
    );

    return result?.data;
  }

  async deleteMarketRequest(requestId: number | string) {
    const result = await this.request(
      ApiMethod.DELETE,
      this.routes.DELETE_MARKET_REQUEST.replace(
        this.dynamicIds.REQUEST_ID,
        requestId.toString()
      )
    );

    return result?.data;
  }

  async approveDelegation(
    requestId: string | number,
    data: ApproveDelegationRequestData
  ) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.APPROVE_DELEGATION.replace(
        this.dynamicIds.REQUEST_ID,
        String(requestId)
      ),
      getExistingValues(data)
    );

    return result?.data;
  }

  async executeBorrow(data: ExecuteBorrowRequestData) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.EXECUTE_BORROW.replace(
        this.dynamicIds.REQUEST_ID,
        data.requestId.toString()
      ),
      { borrowTxHash: data.borrowTxHash }
    );

    return result?.data;
  }

  async applyToOffer(offerId: number) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.APPLY_TO_OFFER.replace(
        this.dynamicIds.REQUEST_ID,
        offerId.toString()
      )
    );

    return result?.data;
  }

  async getApplicationsForOffer(
    offerId: number
  ): Promise<GetApplicationForOfferApiResponse> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_APPLICATIONS_FOR_OFFER.replace(
        this.dynamicIds.REQUEST_ID,
        offerId.toString()
      )
    );

    return result?.data;
  }

  buildPaginationQueryParams(data: GetMarketRequestsData): string {
    const queryParams: string[] = [];
    Object.entries(data).forEach(([key, value]) => {
      queryParams.push(`&${key}=${value}`);
    });

    return queryParams.join('');
  }

  buildQueryString(...queryParams: string[]) {
    let queryString = '';
    let firstQueryParamSet = false;
    queryParams
      .filter((queryParam) => queryParam)
      .forEach((queryParam) => {
        if (firstQueryParamSet) {
          queryString += queryParam;
        } else {
          queryString += `?${queryParam.slice(1)}`;
          firstQueryParamSet = true;
        }
      });

    return queryString;
  }
}

export const marketRequest = new MarketRequest();
