import React from 'react';

export function DeniedIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.5355 4.46447C10.6307 3.55964 9.38071 3 8 3C5.23858 3 3 5.23858 3 8C3 9.38071 3.55964 10.6307 4.46447 11.5355M11.5355 4.46447C12.4404 5.36929 13 6.61929 13 8C13 10.7614 10.7614 13 8 13C6.61929 13 5.36929 12.4404 4.46447 11.5355M11.5355 4.46447L4.46447 11.5355"
        stroke="black"
      />
    </svg>
  );
}

export function DeniedIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.3033 6.6967C15.9461 5.33947 14.0711 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 14.0711 5.33947 15.9461 6.6967 17.3033M17.3033 6.6967C18.6605 8.05393 19.5 9.92893 19.5 12C19.5 16.1421 16.1421 19.5 12 19.5C9.92893 19.5 8.05393 18.6605 6.6967 17.3033M17.3033 6.6967L6.6967 17.3033"
        stroke="black"
      />
    </svg>
  );
}
