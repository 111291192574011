import { useHistory, useLocation } from 'react-router-dom';
import { useMemo } from 'react';

interface History {
  location: { pathname: string };
  push(route: string): void;
}

const pushQueryRoute =
  (history: History): CallbackFunction =>
  (query: URLSearchParams): void => {
    history.push(`${history.location.pathname}?${query.toString()}`);
  };

function useQuery() {
  const history = useHistory();
  const location = useLocation();
  return useMemo(
    () => ({
      query: new URLSearchParams(location.search),
      pushQueryRoute: pushQueryRoute(history),
    }),
    [history, location.search]
  );
}

export default useQuery;
