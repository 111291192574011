import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { notificationsRequest } from 'api/requestWrappers/NotificationsRequest';

export const unreadNotifications = createAsyncThunk('unreadNotifications', () =>
  notificationsRequest.getUnread()
);

export interface NotificationsState {
  unread: number;
}

const initialState: NotificationsState = {
  unread: 0,
};

export const notificationsSlice = createSlice({
  name: 'unreadNotifications',
  initialState,
  reducers: {
    setUnread(state, action: PayloadAction<number>) {
      state.unread = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      unreadNotifications.fulfilled,
      (state: NotificationsState, action) => {
        state.unread = action.payload;
      }
    );
  },
});

export const { setUnread } = notificationsSlice.actions;
export default notificationsSlice.reducer;
