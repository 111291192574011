import { useState, useEffect, useRef, ReactNode } from 'react';
import { usePopper } from 'react-popper';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import outy from 'outy';
import clsx from 'clsx';

import { filrepRequest } from 'api/requestWrappers/FilrepRequest';
import * as SC from './styled';

export type MinersDropdownItemTypes = {
  minerId: string;
  status?: string;
};

type MinersDropdownPropTypes = {
  children: ReactNode;
  options: MinersDropdownItemTypes[];
};

function MinersDropdown({ children, options }: MinersDropdownPropTypes) {
  const [open, setOpen] = useState(false);
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);
  const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null);
  const outyRef = useRef();

  const { styles, attributes, forceUpdate } = usePopper(targetRef, popperRef, {
    placement: 'bottom-start',
    strategy: 'absolute',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      // { name: 'preventOverflow', options: { padding: 16 } },
      { name: 'flip', enabled: false },
    ],
  });

  useEffect(() => {
    if (forceUpdate) {
      forceUpdate();
    }

    const keyboardHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setOpen(false);
      }
    };

    if (open) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      outyRef.current = outy(
        [targetRef, popperRef],
        ['click', 'touchstart'],
        () => setOpen(false)
      );

      document.addEventListener('keyup', keyboardHandler);
    }

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (outyRef.current?.remove) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        outyRef.current.remove();
        outyRef.current = undefined;
      }
      document.removeEventListener('keyup', keyboardHandler);
    };
  }, [open]);

  return (
    <SC.Wrapper>
      <SC.TargetButton
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        ref={setTargetRef}
        type="button"
        className={clsx({ active: open })}
        onClick={() => setOpen((isOpen) => !isOpen)}
        tabIndex={0}
      >
        {children}
      </SC.TargetButton>

      <SC.Dropdown
        ref={setPopperRef}
        style={styles.popper}
        className={clsx({ active: open })}
        {...attributes.popper}
      >
        {options.map((item) => (
          <SC.DropdownItem
            key={item.minerId}
            href={`${filrepRequest.searchUrl}${item.minerId}`}
            rel="noreferrer noopener"
            target="_blank"
          >
            <span>{item.minerId}</span>
          </SC.DropdownItem>
        ))}
      </SC.Dropdown>
    </SC.Wrapper>
  );
}

export default MinersDropdown;
