import React from 'react';

export function SortUpIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 6.66669L7.64645 6.31313L8 5.95958L8.35355 6.31313L8 6.66669ZM8.35355 6.31313L11.3536 9.31313L10.6464 10.0202L7.64645 7.02024L8.35355 6.31313ZM8.35355 7.02024L5.35355 10.0202L4.64645 9.31313L7.64645 6.31313L8.35355 7.02024Z"
        fill="black"
      />
    </svg>
  );
}

export function SortUpIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 10L12.3536 9.64648L12 9.29292L11.6464 9.64648L12 10ZM11.6464 10.3536L16.1464 14.8536L16.8536 14.1465L12.3536 9.64648L11.6464 10.3536ZM11.6464 9.64648L7.14645 14.1465L7.85355 14.8536L12.3536 10.3536L11.6464 9.64648Z"
        fill="currentColor"
      />
    </svg>
  );
}
