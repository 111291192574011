import React from 'react';

export function EditIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 13H12.5M10 6L5.5 10.5H3.49996L3.5 8.49999L8 4M10 6L11.5 4.5L9.5 2.5L8 4M10 6L8 4"
        stroke="black"
      />
    </svg>
  );
}

export function EditIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5 7L15.5 5L17.5 7L15.5 9M13.5 7L6 14.5V16.5H8L15.5 9M13.5 7L15.5 9M5.5 19H18.5"
        stroke="black"
      />
    </svg>
  );
}
