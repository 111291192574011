import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { userAddressSelector } from 'redux/slices/selectors';
import { useSelector } from 'react-redux';
import NotificationWS from 'websocket/NotificationWS';
import toastAlert from 'alerts/ToastAlert';
import { MarketRequestTypeApiResponse } from 'api/requestWrappers/MarketRequest';
import eventEmitter, { EVENTS } from 'eventEmitter';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DefaultTheme, useTheme } from 'styled-components';
import PATH from 'routes/paths';
import { replaceId } from 'routes/patterns';
import { displayWeiAsValueInAssetUnits } from 'helpers/functions/numbers';
import { APPLICATIONS_QUERY_PARAM } from 'components/smart/containers/Lend/Approve';
import { TabKeys } from 'components/smart/containers/Borrow/constant';
import DateFormats from 'helpers/classes/DateFormats';
import {
  NotificationData,
  NotificationType,
  Socket,
  WsLoanMaturityReminder,
  WsMarketItem,
  WsMonthlyRate5DaysReminder,
  WsMonthlyRatePayment,
  WsOverdueLoanPayment,
  WsOverdueMonthlyRatePayment,
  WsPrincipalPayment,
} from './types';

const processLoanMaturityReminder = (
  notificationData: NotificationData,
  theme: DefaultTheme
) => {
  const loanMaturityReminder: WsLoanMaturityReminder = notificationData.data;

  toastAlert.showInfo(
    <Trans
      i18nKey="notifications.ReminderMaturityDate.description"
      values={{
        value: `${displayWeiAsValueInAssetUnits(
          loanMaturityReminder.loan.initialAmount,
          loanMaturityReminder.loan.assetDecimals
        )} ${loanMaturityReminder.loan.assetName}`,
        date: DateFormats.fullDate(loanMaturityReminder.loan.dueDate),
      }}
      components={{
        a: (
          <Link
            style={{ color: theme.palette.raw.white }}
            to={replaceId(PATH.BORROW.LOAN, loanMaturityReminder.loan.id)}
          />
        ),
        bold: <b />,
      }}
    />
  );
};

interface WebSocketProviderProps {
  children: ReactNode;
}

export const WebSocketContext = createContext<Socket>(null);

const maxTimeout = 10000;
const initialTimeout = 250;
let timeout = 250;

export function NotificationSocketProvider({
  children,
}: WebSocketProviderProps) {
  const theme = useTheme();
  const userAddress = useSelector(userAddressSelector);
  const [socket, setSocket] = useState<Socket>(null);

  const connect = () => {
    const ws = NotificationWS.createSocket();

    ws.onmessage = (event) => {
      const notificationData: NotificationData = JSON.parse(event.data);
      eventEmitter.emit(EVENTS.WS.NOTIFICATION.RECEIVED);

      switch (notificationData.name) {
        case NotificationType.MARKET_REQUEST_PUBLISHED: {
          eventEmitter.emit(
            EVENTS.WS.NOTIFICATION.MARKET_REQUEST_PUBLISHED,
            notificationData
          );
          const marketItem: WsMarketItem = notificationData.data?.marketRequest;

          toastAlert.showInfo(
            <>
              <Trans
                i18nKey={`notifications.${
                  notificationData.data.marketRequest.type ===
                  MarketRequestTypeApiResponse.BORROW
                    ? 'LoanRequestPosted'
                    : 'LoanOfferPosted'
                }.description`}
                values={{
                  value: `${displayWeiAsValueInAssetUnits(
                    marketItem.amount,
                    marketItem.assetDecimals
                  )} ${marketItem.assetName}`,
                }}
                components={{ bold: <b /> }}
              />
              .
            </>
          );
          break;
        }
        case NotificationType.MARKET_REQUEST_APPLIED: {
          eventEmitter.emit(
            EVENTS.WS.NOTIFICATION.MARKET_REQUEST_APPLIED,
            notificationData
          );
          const marketItem: WsMarketItem = notificationData.data?.marketRequest;

          toastAlert.showInfo(
            <Trans
              i18nKey="notifications.LoanOfferApplication.description"
              values={{
                value: `${displayWeiAsValueInAssetUnits(
                  marketItem.amount,
                  marketItem.assetDecimals
                )} ${marketItem.assetName}`,
              }}
              components={{
                bold: <b />,
                a: (
                  <Link
                    style={{ color: theme.palette.raw.white }}
                    to={`${replaceId(
                      PATH.LEND.APPROVE,
                      marketItem.id
                    )}?${APPLICATIONS_QUERY_PARAM}=true`}
                  />
                ),
              }}
            />
          );
          break;
        }
        case NotificationType.MARKET_REQUEST_APPROVED: {
          eventEmitter.emit(
            EVENTS.WS.NOTIFICATION.MARKET_REQUEST_APPROVED,
            notificationData
          );
          const marketItem: WsMarketItem = notificationData.data?.marketRequest;

          toastAlert.showInfo(
            <Trans
              i18nKey={`notifications.${
                notificationData.data.marketRequest.type ===
                MarketRequestTypeApiResponse.BORROW
                  ? 'LoanRequestAccepted'
                  : 'LoanApplicationAccepted'
              }.description`}
              values={{
                value: `${displayWeiAsValueInAssetUnits(
                  marketItem.amount,
                  marketItem.assetDecimals
                )} ${marketItem.assetName}`,
              }}
              components={{
                a: (
                  <Link
                    style={{ color: theme.palette.raw.white }}
                    to={`${PATH.BORROW.INDEX}?type=${TabKeys.LOAN_REQUESTS}`}
                  />
                ),
                bold: <b />,
              }}
            />
          );
          break;
        }
        case NotificationType.BORROW_EXECUTED: {
          eventEmitter.emit(
            EVENTS.WS.NOTIFICATION.BORROW_EXECUTED,
            notificationData
          );
          const borrowerName: string | undefined =
            notificationData.data?.borrower?.displayName;

          toastAlert.showInfo(
            <Trans
              i18nKey="notifications.BorrowExecuted.description"
              values={{
                provider: borrowerName || 'Unknown',
              }}
              components={{
                a: (
                  <Link
                    style={{ color: theme.palette.raw.white }}
                    to={replaceId(
                      PATH.LEND.LOAN,
                      notificationData.data?.loan.id
                    )}
                  />
                ),
                bold: <b />,
              }}
            />
          );
          break;
        }
        case NotificationType.PRINCIPAL_PAYMENT: {
          eventEmitter.emit(
            EVENTS.WS.NOTIFICATION.PRINCIPAL_PAYMENT,
            notificationData
          );
          const principalPayment: WsPrincipalPayment = notificationData.data;

          toastAlert.showInfo(
            <Trans
              i18nKey={`notifications.PaymentPrincipal.${
                notificationData.userId === principalPayment.payment.borrowerId
                  ? 'descriptionBorrower'
                  : 'descriptionLender'
              }`}
              values={{
                value: `${displayWeiAsValueInAssetUnits(
                  principalPayment.payment.amount,
                  principalPayment.payment.assetDecimals
                )} ${principalPayment.payment.assetName}`,
                provider: principalPayment.payment.borrowerName || 'Unknown',
              }}
              components={{
                a: (
                  <Link
                    style={{ color: theme.palette.raw.white }}
                    to={replaceId(
                      notificationData.userId ===
                        principalPayment.payment.borrowerId
                        ? PATH.BORROW.LOAN
                        : PATH.LEND.LOAN,
                      principalPayment.loan.id
                    )}
                  />
                ),
                bold: <b />,
              }}
            />
          );
          break;
        }
        case NotificationType.LOAN_MATURITY_1_DAY_REMINDER: {
          eventEmitter.emit(
            EVENTS.WS.NOTIFICATION.LOAN_MATURITY_1_DAY_REMINDER,
            notificationData
          );
          processLoanMaturityReminder(notificationData, theme);
          break;
        }
        case NotificationType.LOAN_MATURITY_7_DAYS_REMINDER: {
          eventEmitter.emit(
            EVENTS.WS.NOTIFICATION.LOAN_MATURITY_7_DAYS_REMINDER,
            notificationData
          );
          processLoanMaturityReminder(notificationData, theme);
          break;
        }
        case NotificationType.LOAN_MATURITY_1_MONTH_REMINDER: {
          eventEmitter.emit(
            EVENTS.WS.NOTIFICATION.LOAN_MATURITY_1_MONTH_REMINDER,
            notificationData
          );
          processLoanMaturityReminder(notificationData, theme);
          break;
        }
        case NotificationType.MONTHLY_RATE_5_DAYS_REMINDER: {
          eventEmitter.emit(
            EVENTS.WS.NOTIFICATION.MONTHLY_RATE_5_DAYS_REMINDER,
            notificationData
          );
          const monthlyRate5DaysReminder: WsMonthlyRate5DaysReminder =
            notificationData.data;

          toastAlert.showInfo(
            <Trans
              i18nKey="notifications.ReminderMonthlyRate.description"
              values={{
                value: `${displayWeiAsValueInAssetUnits(
                  monthlyRate5DaysReminder.rate.amount,
                  monthlyRate5DaysReminder.rate.assetDecimals
                )} ${monthlyRate5DaysReminder.rate.assetName}`,
                date: DateFormats.fullDate(
                  new Date(monthlyRate5DaysReminder.rate.dueDate)
                ),
              }}
              components={{
                a: (
                  <Link
                    style={{ color: theme.palette.raw.white }}
                    to={replaceId(
                      PATH.BORROW.LOAN,
                      monthlyRate5DaysReminder.loan.id
                    )}
                  />
                ),
                bold: <b />,
              }}
            />
          );
          break;
        }
        case NotificationType.MONTHLY_RATE_PAYMENT: {
          eventEmitter.emit(
            EVENTS.WS.NOTIFICATION.MONTHLY_RATE_PAYMENT,
            notificationData
          );
          const monthlyRatePayment: WsMonthlyRatePayment =
            notificationData.data;

          toastAlert.showInfo(
            <Trans
              i18nKey={`notifications.PaymentMonthly.${
                notificationData.userId ===
                monthlyRatePayment.payment.borrowerId
                  ? 'descriptionBorrower'
                  : 'descriptionLender'
              }`}
              values={{
                value: `${displayWeiAsValueInAssetUnits(
                  monthlyRatePayment.payment.amount,
                  monthlyRatePayment.payment.assetDecimals
                )} ${monthlyRatePayment.payment.assetName}`,
                provider: monthlyRatePayment.payment.borrowerName || 'Unknown',
              }}
              components={{
                a: (
                  <Link
                    style={{ color: theme.palette.raw.white }}
                    to={replaceId(
                      notificationData.userId ===
                        monthlyRatePayment.payment.borrowerId
                        ? PATH.BORROW.LOAN
                        : PATH.LEND.LOAN,
                      monthlyRatePayment.loan.id
                    )}
                  />
                ),
                bold: <b />,
              }}
            />
          );
          break;
        }
        case NotificationType.OVERDUE_LOAN_PAYMENT: {
          eventEmitter.emit(
            EVENTS.WS.NOTIFICATION.OVERDUE_LOAN_PAYMENT,
            notificationData
          );
          const overdueLoanPayment: WsOverdueLoanPayment =
            notificationData.data;

          toastAlert.showInfo(
            <Trans
              i18nKey="notifications.OverdueBalancePrincipal.description"
              values={{
                value: `${displayWeiAsValueInAssetUnits(
                  overdueLoanPayment.loan.initialAmount,
                  overdueLoanPayment.loan.assetDecimals
                )} ${overdueLoanPayment.loan.assetName}`,
              }}
              components={{
                a: (
                  <Link
                    style={{ color: theme.palette.raw.white }}
                    to={replaceId(PATH.BORROW.LOAN, overdueLoanPayment.loan.id)}
                  />
                ),
                bold: <b />,
              }}
            />
          );
          break;
        }
        case NotificationType.OVERDUE_MONTHLY_RATE_PAYMENT: {
          eventEmitter.emit(
            EVENTS.WS.NOTIFICATION.OVERDUE_MONTHLY_RATE_PAYMENT,
            notificationData
          );
          const overdueMonthlyRatePayment: WsOverdueMonthlyRatePayment =
            notificationData.data;

          toastAlert.showInfo(
            <Trans
              i18nKey="notifications.OverdueBalanceMonthlyRate.description"
              values={{
                value: `${displayWeiAsValueInAssetUnits(
                  overdueMonthlyRatePayment.loan.initialAmount,
                  overdueMonthlyRatePayment.monthlyRate.assetDecimals
                )} ${overdueMonthlyRatePayment.monthlyRate.assetName}`,
              }}
              components={{
                a: (
                  <Link
                    style={{ color: theme.palette.raw.white }}
                    to={replaceId(
                      PATH.BORROW.LOAN,
                      overdueMonthlyRatePayment.loan.id
                    )}
                  />
                ),
                bold: <b />,
              }}
            />
          );
          break;
        }
        default:
        // no default
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket Error ', error);
    };

    ws.onopen = () => {
      timeout = initialTimeout;
      console.info('WebSocket connection opened.');
      setSocket(ws);
      ws.sendJwtToken();
    };

    ws.onclose = (e) => {
      console.info('WebSocket connection closed.');
      setSocket(null);
      if (!e.wasClean) {
        const newTimeout = Math.min(maxTimeout, (timeout += timeout));
        console.info(
          `Retrying WebSocket connection in ${newTimeout / 1000} seconds`
        );
        setTimeout(connect, newTimeout);
      }
    };
  };

  useEffect(() => {
    if (!userAddress) {
      if (socket) {
        socket.close();
      }
      return;
    }

    connect();
  }, [userAddress]);

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
}
