class TypeChecker {
  static isString = (obj: unknown): obj is string => typeof obj === 'string';

  static isNumber = (obj: unknown): obj is number => typeof obj === 'number';

  static isUndefined = (obj: unknown): obj is undefined =>
    typeof obj === 'undefined';

  static isBoolean = (obj: unknown): obj is boolean => typeof obj === 'boolean';

  static isArray = (obj: unknown): obj is Array<unknown> =>
    obj instanceof Array;

  static isFunction = (obj: unknown): obj is AnyFunction =>
    typeof obj === 'function';
}

export default TypeChecker;
