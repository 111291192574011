import isValid from 'date-fns/isValid';
import formatDate from 'date-fns/format';
import isEqual from 'date-fns/isEqual';
import formatDuration from 'date-fns/formatDuration';
import TypeChecker from './TypeChecker';

enum Formats {
  FULL_DATE = 'MMM. d, yyyy',
  FULL_DATE_ALT = 'MMM. dd.yyyy',
  FULL_MONTH = 'MMMM',
  MONTH = 'MM',
  MONTH_AND_DAY = 'MMM. dd',
}

class DateFormats {
  static FORMATS = Formats;

  static isDateValid = (
    date: Date | string | undefined
  ): date is string | Date => Boolean(date && isValid(new Date(date)));

  static areDatesEqual = (date1: Date, date2: Date): boolean => {
    const isEveryDateValid: boolean = [date1, date2].every(this.isDateValid);

    if (!isEveryDateValid) {
      throw new Error('Dates are invalid!');
    }

    return isEqual(date1, date2);
  };

  static validateAndFormat(
    date: Date | string | undefined,
    format: Formats,
    fallback = '--'
  ): string {
    if (this.isDateValid(date)) {
      return formatDate(new Date(date), format);
    }
    return fallback;
  }

  static fullDate(date: Date | string | undefined, fallback?: string): string {
    return this.validateAndFormat(date, this.FORMATS.FULL_DATE, fallback);
  }

  static fullDateAlt(
    date: Date | string | undefined,
    fallback?: string
  ): string {
    return this.validateAndFormat(date, this.FORMATS.FULL_DATE_ALT, fallback);
  }

  static fullMonth(date: Date | string | undefined, fallback?: string): string {
    return this.validateAndFormat(date, this.FORMATS.FULL_MONTH, fallback);
  }

  static month(date: Date | string | undefined, fallback?: string): string {
    return this.validateAndFormat(date, this.FORMATS.MONTH, fallback);
  }

  static monthAndDay(
    date: Date | string | undefined,
    fallback?: string
  ): string {
    return this.validateAndFormat(date, this.FORMATS.MONTH_AND_DAY, fallback);
  }

  static pluralMonthCount(count?: number | null, fallback?: string): string {
    return TypeChecker.isNumber(count)
      ? formatDuration(
          { months: count || 0 },
          { format: ['months'], zero: true }
        )
      : fallback || '--';
  }

  static pluralDayCount(count?: number | null, fallback?: string): string {
    return TypeChecker.isNumber(count)
      ? formatDuration({ days: count || 0 }, { format: ['days'], zero: true })
      : fallback || '--';
  }
}

export default DateFormats;
