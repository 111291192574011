import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const TargetButton = styled.button`
  background: transparent;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.palette.text};
  margin: 0;
  cursor: pointer;
  border: 0;
  text-decoration: underline;

  &:hover,
  &:focus:not(&:hover),
  &.active {
    color: ${({ theme }) => theme.palette.textSecondary};
  }
`;

export const Dropdown = styled.div`
  background: ${({ theme }) => theme.palette.raw.white};
  border: 1px solid ${({ theme }) => theme.palette.separators.whiteBg};
  box-sizing: border-box;
  box-shadow: 0 4px 16px -8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 4px;
  display: none;
  z-index: 1;

  &.active {
    display: block;
  }
`;

export const DropdownItem = styled.a`
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  width: 100%;
  background: transparent;
  border: 0;
  font-family: inherit;
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(-4)};
  line-height: ${({ theme }) => theme.typography.fontSize};
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${({ theme }) => theme.palette.textSecondary};
  letter-spacing: 0;
  text-align: left;

  &:hover {
    background: ${({ theme }) => theme.palette.background};
  }
`;
