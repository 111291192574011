import {
  FLP_WS_HOST,
  FLP_WS_PORT,
  FLP_WS_PROTOCOL,
} from '../helpers/constants/envVars';
import FLWebSocket from './wrappers/FLWebSocket';

export default class WS {
  static EVENTS = {
    OPEN: 'open',
    CLOSE: 'close',
    MESSAGE: 'message',
    ERROR: 'error',
  };

  static READY_STATE = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
  };

  static get wsProtocol(): string {
    return FLP_WS_PROTOCOL;
  }

  static get wsHost(): string {
    return FLP_WS_HOST;
  }

  static get wsPort(): string {
    return FLP_WS_PORT;
  }

  static get wsUrl(): string {
    return `${this.wsProtocol}://${this.wsHost}${
      this.wsPort ? `:${this.wsPort}` : ''
    }${this.wsEntryPoint}`;
  }

  static get wsEntryPoint(): string {
    return '';
  }

  static createSocket(protocols?: string | string[]): FLWebSocket {
    return new FLWebSocket(this.wsUrl, protocols);
  }
}
