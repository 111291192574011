export enum KnownTokens {
  ETH = 'ETH',
  WETH = 'WETH',
  FIL = 'FIL',
  RENFIL = 'RENFIL',
  DAI = 'DAI',
  USDC = 'USDC',
  USDT = 'USDT',
}

export enum HealthKeys {
  RISKY = 'risky',
  OK = 'ok',
  GOOD = 'good',
}

export enum HealthValues {
  MIN = 0,
  MAX = 6,
}
