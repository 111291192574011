import React from 'react';

export function CloseIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.0001 4.00005L8.00003 7.99999M8.00003 7.99999L12 12M8.00003 7.99999L12 4.00003M8.00003 7.99999L4.0001 11.9999"
        stroke="black"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CloseIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.99996 6L12 12M12 12L18 18M12 12L18 6M12 12L6 18"
        stroke="currentColor"
        strokeLinejoin="round"
      />
    </svg>
  );
}
