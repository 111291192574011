import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import assetsReducer from './slices/assets';
import notificationsReducer from './slices/notifications';

const store = configureStore({
  reducer: {
    auth: authReducer,
    assets: assetsReducer,
    notifications: notificationsReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
