import { idPattern } from 'routes/patterns';

const PATH = Object.freeze({
  INDEX: '/',
  MARKET: {
    INDEX: '/market',
    EDIT_MARKET_ITEM: `/market/${idPattern}/edit`,
  },
  BORROW: {
    INDEX: '/borrow',
    CREATE_LOAN_REQUEST: '/borrow/create-loan-request',
    CLAIM_MINER: {
      MINER: '/borrow/claim-miners',
      SIGNATURE: '/borrow/claim-miners/:id',
    },
    LOAN: `/borrow/${idPattern}`,
    REPAY: `/repay/${idPattern}`,
  },
  LEND: {
    INDEX: '/lend',
    LOAN: `/lend/${idPattern}`,
    APPROVE: `/lend/${idPattern}/approve`,
    CREATE_DELEGATION_OFFER: '/lend/create-delegation-offer',
  },
  NOTIFICATIONS: {
    INDEX: '/notifications',
  },
  TIME_MACHINE: {
    INDEX: '/time-machine',
  },
  READ_MORE: {
    INDEX: '/read-more',
  },
});

export interface LendApproveDelegationParams {
  id: string;
}

export interface ClaimMinerParams {
  id: string;
}

export interface LoanParams {
  id: string;
}

export default PATH;
