export default {
  notifications: {
    header: 'Notifications <counter>{{count}}</counter>',
    title: 'Notifications <light>{{ count }}</light>',
    date: 'Date',
    type: 'Type',
    description: 'Description',
    LoanRequestPosted: {
      state: 'POSTED',
      type: 'Loan request',
      description: `Your <bold>{{ value }}</bold> loan request has been successfully published`,
    },
    LoanRequestAccepted: {
      state: 'ACCEPTED',
      type: 'Loan request',
      description: `Your loan request has been approved by a delegator<br/>For the funds to get transferred to your wallet you must execute the borrow  <a>Execute now</a>`,
    },
    LoanApplicationAccepted: {
      state: 'ACCEPTED',
      type: 'Loan application',
      description: `Your application for the <bold>{{ value }}</bold> loan has been accepted<br/>For the funds to get transferred to your wallet you must execute the borrow  <a>Execute now</a>`,
    },
    LoanApplicationRejected: {
      state: 'REJECTED',
      type: 'Loan application',
      description: `Your application for the <bold>{{ value }}</bold> loan has been canceled`,
    },
    LoanOfferPosted: {
      state: 'POSTED',
      type: 'Loan offer',
      description: `Your <bold>{{ value }}</bold> loan offer has been successfully published`,
    },
    LoanOfferApplication: {
      state: 'APPLICATION',
      type: 'Loan offer',
      description: `A Storage provider has applied to your <bold>{{ value }}</bold> loan offer  <a>See details</a>`,
    },
    BorrowExecuted: {
      state: 'EXECUTED',
      type: 'Borrow',
      description: `The storage provider <bold>{{ provider }}</bold> has executed the borrow on the loan you approved. The funds have been transferred to its address and the loan has become active  <a>See details</a>`,
    },
    PaymentMonthly: {
      state: 'MONTHLY',
      type: 'Payment',
      descriptionLender: `You’ve received a monthly payment of <bold>{{ value }}</bold> from {{ provider }}  <a>See details</a>`,
      descriptionBorrower: `You have successfully made a monthly payment of <bold>{{ value }}</bold>  <a>See details</a>`,
    },
    PaymentPrincipal: {
      state: 'MATURITY',
      type: 'Payment',
      descriptionLender: `{{ provider}} has made a repayment of <bold>{{ value }}</bold>  <a>See details</a>`,
      descriptionBorrower: `You have successfully made a repayment of <bold>{{ value }}</bold>  <a>See details</a>`,
    },
    ReminderMonthlyRate: {
      state: 'MONTHLY RATE',
      type: 'Reminder',
      description: `Your monthly rate of <bold>{{ value }}</bold> has to be paid until {{ date }}<br />Failure to repay in time will lead to a lower credit score  <a>Check loan details</a>`,
    },
    ReminderMaturityDate: {
      state: 'MATURITY DATE',
      type: 'Reminder',
      description: `The maturity date for your <bold>{{ value }}</bold> loan is {{ date }}. Make sure you repay all the loan amount until that date. Failing to pay in time dramatically diminishes your credit score  <a>Check loan details</a>`,
    },
    OverdueBalanceMonthlyRate: {
      state: 'MONTHLY RATE',
      type: 'Overdue Balance',
      description: `You missed your monthly payment for your <bold>{{ value }}</bold> loan<br />Failing to pay in time lowers your credit score  <a>Check loan details</a>`,
    },
    OverdueBalancePrincipal: {
      state: 'MATURITY',
      type: 'Overdue Balance',
      description: `The maturity date for your <bold>{{ value }}</bold> loan has expired<br />Failing to pay in time dramatically lowers your credit score  <a>Check loan details</a>`,
    },
  },
};
