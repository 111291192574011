import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authRequest, LoginData } from '../../api/requestWrappers/AuthRequest';
import EnableTokensClientStore from '../../clientStore/EnableTokensClientStore';

export const login = createAsyncThunk('auth/login', (loginData: LoginData) =>
  authRequest.login(loginData)
);

export const logout = createAsyncThunk('auth/logout', () =>
  authRequest.logout().then((passthroughData) => {
    EnableTokensClientStore.clear();
    return passthroughData;
  })
);

export const me = createAsyncThunk('auth/me', () =>
  authRequest.me().catch((passthroughData) => {
    EnableTokensClientStore.clear();
    throw passthroughData;
  })
);

export interface AuthState {
  user: {
    address: string | null;
    id: number | null;
    displayName: string | null;
    createdAt: string | null;
    hasClaimedMiners: boolean | null;
  };
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  user: {
    address: null,
    id: null,
    displayName: null,
    createdAt: null,
    hasClaimedMiners: null,
  },
  isAuthenticated: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserHasClaimedMiners(state, action: PayloadAction<boolean>) {
      state.user.hasClaimedMiners = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state: AuthState, action) => {
      state.user = action.payload.user;
      state.isAuthenticated = true;
    });
    builder.addCase(logout.fulfilled, () => ({
      ...initialState,
      isAuthenticated: false,
    }));
    builder.addCase(me.fulfilled, (state: AuthState, action) => {
      state.user = { ...state.user, ...action.payload };
      state.isAuthenticated = true;
    });
    builder.addCase(me.rejected, () => ({
      ...initialState,
      isAuthenticated: false,
    }));
  },
});

export const { setUserHasClaimedMiners } = authSlice.actions;
export default authSlice.reducer;
