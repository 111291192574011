import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const Borrow = lazy(() => import('components/smart/containers/Borrow'));
const CreateLoanRequest = lazy(
  () => import('components/smart/containers/Borrow/CreateLoanRequest')
);
const ClaimMiners = lazy(
  () => import('components/smart/containers/Borrow/ClaimMiners')
);
const Loan = lazy(() => import('components/smart/containers/Borrow/Loan'));
const Repay = lazy(() => import('components/smart/containers/Borrow/Repay'));

const BORROW_INDEX: Route = {
  component: Borrow,
  path: PATH.BORROW.INDEX,
  isExact: true,
  isProtected: false,
};

const BORROW_CREATE_LOAN_REQUEST: Route = {
  component: CreateLoanRequest,
  path: PATH.BORROW.CREATE_LOAN_REQUEST,
  isExact: true,
  isProtected: true,
};

const BORROW_CLAIM_MINERS: Route = {
  component: ClaimMiners,
  path: [PATH.BORROW.CLAIM_MINER.MINER, PATH.BORROW.CLAIM_MINER.SIGNATURE],
  isExact: true,
  isProtected: true,
};

const BORROW_LOAN: Route = {
  component: Loan,
  path: PATH.BORROW.LOAN,
  isExact: true,
  isProtected: true,
};

const BORROW_REPAY: Route = {
  component: Repay,
  path: PATH.BORROW.REPAY,
  isExact: true,
  isProtected: true,
};

export default [
  BORROW_REPAY,
  BORROW_LOAN,
  BORROW_CLAIM_MINERS,
  BORROW_CREATE_LOAN_REQUEST,
  BORROW_INDEX,
];
