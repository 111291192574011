import { useAppSelector } from 'hooks/redux';
import { userAddressSelector } from 'redux/slices/selectors';
import { BigNumber, Contract, ethers } from 'ethers';
import variableRateDebtAbi from 'ethereum/abis/variableRateDebt';
import { marketRequest } from 'api/requestWrappers/MarketRequest';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import toastAlert from 'alerts/ToastAlert';
import MetaMaskErrorCodes, {
  isProviderRpcError,
} from 'ethereum/metamask/constants/errorCodes';

const { Web3Provider } = ethers.providers;
const { ethereum } = window;

const useApproveDelegation = () => {
  const { t } = useTranslation();
  const userAddress = useAppSelector(userAddressSelector);
  const [approveDelegationPending, setApproveDelegationPending] =
    useState(false);

  const approveDelegation = async (
    requestId: number,
    borrowerAddress: string,
    variableDebtTokenAddress: string,
    tokenCreditValue: BigNumber,
    applicantId?: number
  ) => {
    if (!userAddress || !ethereum?.isConnected()) {
      throw new Error('User is not logged in to MetaMask!');
    }
    if (approveDelegationPending) {
      const message = t('metamask.warnings.approveDelegationPending');
      toastAlert.showWarning(message);
      throw new Error(message);
    }

    const provider = new Web3Provider(ethereum);
    const signer = await provider.getSigner();

    const variableRateDebtContract = new Contract(
      variableDebtTokenAddress,
      variableRateDebtAbi,
      signer
    );

    setApproveDelegationPending(true);
    try {
      const { hash }: { hash: string } =
        await variableRateDebtContract.approveDelegation(
          borrowerAddress,
          tokenCreditValue
        );

      return await marketRequest.approveDelegation(requestId, {
        approvalTxHash: hash,
        applicantId,
      });
    } catch (e) {
      if (
        isProviderRpcError(e) &&
        e.code === MetaMaskErrorCodes.REQUEST_REJECTED_BY_USER
      ) {
        toastAlert.showWarning(
          t('metamask.warnings.approveDelegationRejected')
        );
      } else {
        toastAlert.showError(t('general.unknownError'));
      }
      throw e;
    } finally {
      setApproveDelegationPending(false);
    }
  };

  return { approveDelegation };
};

export default useApproveDelegation;
