import TypeChecker from '../../../helpers/classes/TypeChecker';

export interface ProviderRpcError extends Error {
  message: string;
  code: number;
  data?: unknown;
}

export function isProviderRpcError(error: unknown): error is ProviderRpcError {
  return (
    TypeChecker.isString((error as ProviderRpcError)?.message) &&
    TypeChecker.isNumber((error as ProviderRpcError)?.code)
  );
}

enum MetaMaskErrorCodes {
  ALREADY_PROCESSING_ETH_REQUEST_ACCOUNTS = -32002,
  REQUEST_REJECTED_BY_USER = 4001,
}

export default MetaMaskErrorCodes;
