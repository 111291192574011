export default {
  market: {
    requests: 'Requests',
    offers: 'Offers',
    cantApproveYourOwnRequest: "You can't approve your own request",
    cantApplyToYourOwnOffer: "You can't apply to your own offer",
    connectWalletToApproveRequest:
      'You need to connect to your wallet to approve the request',
    connectWalletToApplyToOffer:
      'You need to connect to your wallet to apply to the offer',
    successfullyAppliedToOffer: 'Successfully applied to the offer.',
    alreadyAppliedToOffer: 'Already applied to the offer.',
    miners: 'miners',
    months: 'months',
    requestColumn: {
      asset: 'Asset',
      requester: 'Requester',
      reputationScore: 'Reputation Score',
      creditScore: 'Credit Score',
      annualIR: 'Annual IR',
      duration: 'DURATION',
      action: 'Action',
    },
    offerColumn: {
      asset: 'Asset',
      offerer: 'Offerer',
      annualIR: 'Annual IR',
      duration: 'DURATION',
      action: 'Action',
    },
    deleteMarketItem: 'Are you sure you want to delete?',
    deleteMarketItemModal: {
      title: 'Are you sure you want to cancel your offer?',
      description: 'This operation cannot be undone.',
      confirmButton: 'Yes, I`m sure',
      onSuccess: 'Request deleted!',
      onError: 'Request not deleted!',
    },
  },
};
