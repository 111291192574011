import React from 'react';

import { ArrowRightIcon16px, ArrowRightIcon24px } from './ArrowRightIcon';
import { ArrowLeftIcon16px, ArrowLeftIcon24px } from './ArrowLeftIcon';
import { DeniedIcon16px, DeniedIcon24px } from './DeniedIcon';
import { SortDownIcon16px, SortDownIcon24px } from './SortDownIcon';
import { SortUpIcon16px, SortUpIcon24px } from './SortUpIcon';
import { ExternalIcon16px, ExternalIcon24px } from './ExternalIcon';
import { CheckIcon16px, CheckIcon24px } from './CheckIcon';
import { CloseIcon16px, CloseIcon24px } from './CloseIcon';
import { QuestionIcon16px, QuestionIcon24px } from './QuestionIcon';
import { QuestionAltIcon16px, QuestionAltIcon24px } from './QuestionAltIcon';
import { MenuIcon16px, MenuIcon24px } from './MenuIcon';
import { SpinnerIcon16px, SpinnerIcon24px } from './SpinnerIcon';
import { MoreIcon16px, MoreIcon24px } from './MoreIcon';
import { SearchIcon16px, SearchIcon24px } from './SearchIcon';
import { InfoIcon16px } from './InfoIcon';
import { InfoAltIcon16px } from './InfoAltIcon';
import { AddIcon16px, AddIcon24px } from './AddIcon';
import { ApplicationIcon16px, ApplicationIcon24px } from './ApplicationIcon';
import { SettingsIcon16px, SettingsIcon24px } from './SettingsIcon';
import { ArrowNextIcon16px, ArrowNextIcon24px } from './ArrowNextIcon';
import { ArrowPrevIcon16px, ArrowPrevIcon24px } from './ArrowPrevIcon';
import { ExternalAltIcon16px, ExternalAltIcon24px } from './ExternalAltIcon';
import { PauseIcon16px, PauseIcon24px } from './PauseIcon';
import { DeleteIcon16px, DeleteIcon24px } from './DeleteIcon';
import { EditIcon16px, EditIcon24px } from './EditIcon';
import { MailIcon16px, MailIcon24px } from './MailIcon';
import { LinkIcon16px, LinkIcon24px } from './LinkIcon';
import { ETHIcon24px, ETHIcon32px } from './currency/ETHIcon';
import { DAIIcon24px, DAIIcon32px } from './currency/DAIIcon';
import { FILIcon24px, FILIcon32px } from './currency/FILIcon';
import { USDCIcon24px, USDCIcon32px } from './currency/USDCIcon';
import { RedoIcon16px, RedoIcon24px } from './RedoIcon';

const icons = [
  {
    name: 'ArrowRight',
    size16: <ArrowRightIcon16px />,
    size24: <ArrowRightIcon24px />,
  },
  {
    name: 'ArrowLeft',
    size16: <ArrowLeftIcon16px />,
    size24: <ArrowLeftIcon24px />,
  },
  {
    name: 'Denied',
    size16: <DeniedIcon16px />,
    size24: <DeniedIcon24px />,
  },
  {
    name: 'SortDown',
    size16: <SortDownIcon16px />,
    size24: <SortDownIcon24px />,
  },
  {
    name: 'SortUp',
    size16: <SortUpIcon16px />,
    size24: <SortUpIcon24px />,
  },
  {
    name: 'External',
    size16: <ExternalIcon16px />,
    size24: <ExternalIcon24px />,
  },
  {
    name: 'Check',
    size16: <CheckIcon16px />,
    size24: <CheckIcon24px />,
  },
  {
    name: 'Close',
    size16: <CloseIcon16px />,
    size24: <CloseIcon24px />,
  },
  {
    name: 'Question',
    size16: <QuestionIcon16px />,
    size24: <QuestionIcon24px />,
  },
  {
    name: 'QuestionAlt',
    size16: <QuestionAltIcon16px />,
    size24: <QuestionAltIcon24px />,
  },
  {
    name: 'Menu',
    size16: <MenuIcon16px />,
    size24: <MenuIcon24px />,
  },
  {
    name: 'More',
    size16: <MoreIcon16px />,
    size24: <MoreIcon24px />,
  },
  {
    name: 'Spinner',
    size16: <SpinnerIcon16px />,
    size24: <SpinnerIcon24px />,
  },
  {
    name: 'Search',
    size16: <SearchIcon16px />,
    size24: <SearchIcon24px />,
  },
  {
    name: 'Info',
    size16: <InfoIcon16px />,
  },
  {
    name: 'InfoAlt',
    size16: <InfoAltIcon16px />,
  },
  {
    name: 'Add',
    size16: <AddIcon16px />,
    size24: <AddIcon24px />,
  },
  {
    name: 'Application',
    size16: <ApplicationIcon16px />,
    size24: <ApplicationIcon24px />,
  },
  {
    name: 'Settings',
    size16: <SettingsIcon16px />,
    size24: <SettingsIcon24px />,
  },
  {
    name: 'ArrowNext',
    size16: <ArrowNextIcon16px />,
    size24: <ArrowNextIcon24px />,
  },
  {
    name: 'ArrowPrev',
    size16: <ArrowPrevIcon16px />,
    size24: <ArrowPrevIcon24px />,
  },
  {
    name: 'ExternalAlt',
    size16: <ExternalAltIcon16px />,
    size24: <ExternalAltIcon24px />,
  },
  {
    name: 'Pause',
    size16: <PauseIcon16px />,
    size24: <PauseIcon24px />,
  },
  {
    name: 'Delete',
    size16: <DeleteIcon16px />,
    size24: <DeleteIcon24px />,
  },
  {
    name: 'Edit',
    size16: <EditIcon16px />,
    size24: <EditIcon24px />,
  },
  {
    name: 'Mail',
    size16: <MailIcon16px />,
    size24: <MailIcon24px />,
  },
  {
    name: 'Link',
    size16: <LinkIcon16px />,
    size24: <LinkIcon24px />,
  },
  {
    name: 'ETH',
    size24: <ETHIcon24px />,
    size32: <ETHIcon32px />,
  },
  {
    name: 'DAI',
    size24: <DAIIcon24px />,
    size32: <DAIIcon32px />,
  },
  {
    name: 'FIL',
    size24: <FILIcon24px />,
    size32: <FILIcon32px />,
  },
  {
    name: 'USDC',
    size24: <USDCIcon24px />,
    size32: <USDCIcon32px />,
  },
  {
    name: 'Redo',
    size16: <RedoIcon16px />,
    size24: <RedoIcon24px />,
  },
];

function IconsCatalog() {
  const thStyles = { padding: '2px 16px' };
  const tdStyles = { padding: '0px 16px' };

  return (
    <table>
      <thead>
        <tr>
          <th style={thStyles}>name</th>
          <th style={thStyles}>16px</th>
          <th style={thStyles}>24px</th>
          <th style={thStyles}>32px</th>
        </tr>
      </thead>
      <tbody>
        {icons.map(({ name, size16, size24, size32 }, idx) => (
          <tr key={String(idx)}>
            <td style={tdStyles}>{name}</td>
            <td style={tdStyles}>{size16}</td>
            <td style={tdStyles}>{size24}</td>
            <td style={tdStyles}>{size32}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default IconsCatalog;
