import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { HealthKeys } from 'config';
import { LendCardStatus } from './types';

export const Wrapper = styled.div`
  padding: 24px 0 18px;
  border: 1px solid ${({ theme }) => theme.palette.background};
  background: ${({ theme }) => theme.palette.background};
  border-radius: 16px;
  position: relative;

  &.${LendCardStatus.Initial} {
    border: 1px solid ${({ theme }) => theme.palette.background};
  }

  &.${LendCardStatus.Health} {
    border: 1px solid ${({ theme }) => theme.palette.separators.whiteBg};
    background: ${({ theme }) => theme.palette.raw.white};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0 24px;

  > * + * {
    margin-left: 24px;
  }
`;

export const LinkButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.palette.raw.white};
  border: 1px solid ${({ theme }) => theme.palette.sets.green.main};
  color: ${({ theme }) => theme.palette.sets.green.main};
  border-radius: 100px;
  position: relative;

  :hover {
    background: ${({ theme }) => theme.palette.sets.green.main};
    color: ${({ theme }) => theme.palette.raw.white};
  }
`;

export const Button = styled(LinkButton).attrs((attrs) => ({
  ...attrs,
  as: 'button',
}))`
  padding: 0;
`;

export const Body = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSize};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ theme }) => theme.typography.pxToRem(2)};
  letter-spacing: 0;
  color: ${({ theme }) => theme.palette.textSecondary};
  margin-bottom: 8px;
  padding: 0 24px;

  > span {
    display: block;
    color: ${({ theme }) => theme.palette.text};
  }
`;

export const Footer = styled.div`
  font-size: ${({ theme }) => theme.typography.pxToRem(-4)};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ theme }) => theme.typography.fontSize};
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.palette.textSecondary};
  padding: 0 24px;
`;

export const Health = styled.span`
  padding: 4px 8px;
  background: ${({ theme }) => theme.palette.raw.white};
  border: 1px solid ${({ theme }) => theme.palette.raw.black};
  box-sizing: border-box;
  border-radius: 6px;
  color: ${({ theme }) => theme.palette.raw.black};

  &.${HealthKeys.RISKY} {
    background: ${({ theme }) => theme.palette.sets.red.light};
    border-color: ${({ theme }) => theme.palette.sets.red.main};
    color: ${({ theme }) => theme.palette.sets.red.main};
  }

  &.${HealthKeys.OK} {
    background: ${({ theme }) => theme.palette.sets.yellow.light};
    border-color: ${({ theme }) => theme.palette.sets.yellow.main};
    color: ${({ theme }) => theme.palette.sets.yellow.main};
  }

  &.${HealthKeys.GOOD} {
    background: ${({ theme }) => theme.palette.sets.green.light};
    border-color: ${({ theme }) => theme.palette.sets.green.main};
    color: ${({ theme }) => theme.palette.sets.green.main};
  }
`;

export const SelectApplicationIconButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.palette.sets.green.main};
  background-color: ${({ theme }) => theme.palette.sets.green.light};
  border-radius: 16px;

  :hover {
    color: ${({ theme }) => theme.palette.raw.white};
    background-color: ${({ theme }) => theme.palette.sets.green.main};
  }
`;

export const VerticallyCenteredFlexContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    font-weight: 600;

    &.${HealthKeys.RISKY} {
      color: ${({ theme }) => theme.palette.sets.red.main};
    }

    &.${HealthKeys.OK} {
      color: ${({ theme }) => theme.palette.sets.yellow.main};
    }

    &.${HealthKeys.GOOD} {
      color: ${({ theme }) => theme.palette.sets.green.main};
    }
  }
`;
