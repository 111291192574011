export default {
  lend: {
    depositTitle: 'Deposit <light>information</light> ',
    depositParagraph:
      'The first step is to deposit funds on AAve. These funds can be used as collateral for your future loans and credit delegations.',
    borrowTitle: 'Borrow <light>information</light>',
    borrowParagraph:
      'Once you’ve deposited funds and established a collateral value you will be able to borrow or create credit delegation offers.',
    approximateValue: 'Approximate value',
    depositFunds: 'Deposit funds',
    risky: 'Risky',
    ok: 'Ok',
    safe: 'Safe',
    good: 'Good',
    borrowedAmount: 'Borrowed amount',
    maximize: 'Maximize',
    minimize: 'Minimize',
    healthFactor: 'Health factor',
    currentLTV: 'Current LTV',
    collateralValue: 'Collateral value',
    borrowingPowerUsed: 'Borrowing power used',
    collateral: 'Collateral',
    composition: 'Composition',
    borrow: 'Borrow',
    hideList: 'Hide list',
    showList: 'Show list',
    deposit: 'Deposit',
    createCreditDelegationOffer: 'Create credit delegation offer',
    waitingForMiner: 'Waiting for miner ...',
    creditDelegationIsActive: 'credit delegation is active',
    minerInfo: 'Miner info',
    creditDelegationOffer: 'Credit delegation offer',
    publishYourOffer: 'Publish your offer',
    approveApplication: 'Approve application',
    viewApplicants: 'View applicants',
    noApplications: 'No applications',
    miners: 'miners',
    minersWithCount_one: '{{count}} miner',
    minersWithCount_other: '{{count}} miners',
    myCreditDelegationOffers: `My credit delegation offers <highlight>{{count}}</highlight>`,
    myActiveDelegationLoans: `Active loans <highlight>{{count}}</highlight>`,
    createOffer: 'Create offer',
    information: 'Deposit/Borrow info',
    myOffers: 'My loan offers/approvals',
    loanInformation: 'Loan information',
    activeLoans: 'Active loans',
    closedLoans: 'Closed loans',
    monthlyPayments: 'Monthly payments',
    toBePaidAtMaturity: 'To be paid at maturity',
    principal: 'Principal',
    accruedInterest: 'Accrued interest',
    toBePaidToAAve: 'To be paid to AAve',
    AAveVariableRate: 'AAve variable rate',
    toBePaidToMe: 'To be paid to me',
    maturityDate: 'Maturity date',
    totalBalanceDue: 'Total balance due',
    monthlyRate: 'Monthly rate',
    apr: 'Annual interest rate',
    amount: 'AMOUNT',
    date: 'DATE',
    type: 'TYPE',
    to: 'TO',
    status: 'STATUS',
    txHash: 'TX HASH',
    statusOptions: {
      ok: 'OK',
      pending: 'PENDING',
      failed: 'FAILED',
    },
    paymentHistory: 'Payment history',
    appNoMinersClaimed: 'Application has no miners claimed',
    paymentHistorySection: {
      maturity: 'Maturity',
      monthly: 'Monthly',
      aave: 'Aave',
    },
    editDelegationOffer: 'Edit delegation offer',
    futureHealthFactorAtLeastOne:
      'For safety reasons, you should have enough collateral so that after approving the loan, your health factor stays above 2.',
  },
};
