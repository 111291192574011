import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

const StyledToastContainer = styled(ToastContainer)`
  --toastify-toast-width: 50%;
  --toastify-font-family: ${({ theme }) => theme.typography.fontFamily},
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  .Toastify__toast {
    border-radius: 16px;
    background-color: ${({ theme }) => theme.palette.raw.black};
    color: ${({ theme }) => theme.palette.raw.white};
    padding: 14px;
  }
  .Toastify__toast-body {
    padding: 10px;
    > div {
      text-align: center;
    }
  }
  .Toastify__close-button {
    color: ${({ theme }) => theme.palette.raw.white};
  }
`;

export default StyledToastContainer;
