import styled from 'styled-components';

export const Avg = styled.span`
  color: ${({ theme }) => theme.palette.text};
`;

export const Wrapper = styled.span`
  padding: 2px 4px;
  font-size: ${({ theme }) => theme.typography.pxToRem(-7)};
  font-style: normal;
  font-weight: 700;
  line-height: ${({ theme }) => theme.typography.pxToRem(-4)};
  letter-spacing: 0.04em;
  text-align: left;
  color: ${({ theme }) => theme.palette.textSecondary};
  border: 1px solid ${({ theme }) => theme.palette.raw.black};
  border-radius: 3px;
  white-space: nowrap;
  position: relative;

  &.big {
    background: ${({ theme }) => theme.palette.separators.whiteBg};
    font-size: ${({ theme }) => theme.typography.pxToRem(4)};
    font-style: normal;
    font-weight: 400;
    line-height: ${({ theme }) => theme.typography.pxToRem(8)};
    letter-spacing: -0.04em;
    padding: 3px 5px;

    ${Avg} {
      position: absolute;
      top: -8px;
      right: -10px;
      font-size: ${({ theme }) => theme.typography.pxToRem(-7)};
      font-weight: 700;
      line-height: ${({ theme }) => theme.typography.pxToRem(-4)};
      letter-spacing: 0.04em;
      text-align: center;
      background: ${({ theme }) => theme.palette.raw.white};
      border: 1px solid ${({ theme }) => theme.palette.raw.black};
      border-radius: 3px;
      padding: 0 3px;
      color: ${({ theme }) => theme.palette.textSecondary};
    }
  }
`;
