import { toast, ToastOptions } from 'react-toastify';
import { ReactNode } from 'react';
import { Alert } from './Alert';

export const defaultToastOptions: ToastOptions = {
  position: 'bottom-center',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  icon: false,
};

export class ToastAlert implements Alert {
  private readonly options: ToastOptions;

  constructor(options: ToastOptions = defaultToastOptions) {
    this.options = options;
  }

  showInfo(text: ReactNode): void {
    toast.info(text, this.options);
  }

  showSuccess(text: ReactNode): void {
    toast.success(text, this.options);
  }

  showWarning(text: ReactNode): void {
    toast.warning(text, this.options);
  }

  showError(text: ReactNode): void {
    toast.error(text, this.options);
  }

  static create(options: ToastOptions) {
    return new ToastAlert(options);
  }
}

const toastAlert = new ToastAlert();

export default toastAlert;
