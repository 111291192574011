import { ApiMethod } from './constants';
import AbstractExternalRequest from './AbstractExternalRequest';

export class CoinGeckoRequest extends AbstractExternalRequest {
  get apiUrl(): string {
    return 'https://api.coingecko.com/api/v3';
  }

  get routes(): Record<string, string> {
    return {
      GET_SIMPLE_PRICE: `${this.apiUrl}/simple/price`,
    };
  }

  async getEthPriceInUsd() {
    const response = await this.request(
      ApiMethod.GET,
      `${this.routes.GET_SIMPLE_PRICE}?ids=ethereum&vs_currencies=usd`
    );

    return response?.data?.ethereum?.usd;
  }
}

export const coinGeckoRequest = new CoinGeckoRequest();
