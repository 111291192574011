import styled from 'styled-components';
import LendCard from 'components/dumb/MiniCards/Lend';
import CardButton from 'components/dumb/CardButton';

export const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
`;

export const Separator = styled.div`
  margin: 0 20px;
  color: ${({ theme }) => theme.palette.text};
  align-self: center;
`;

export const StyledCard = styled(LendCard)`
  & + & {
    margin-left: 16px;
  }
`;

export const StyledCardButton = styled(CardButton)`
  padding: 24px;
  max-width: 140px;
`;

export const NoApplicationsH3 = styled.h3`
  display: flex;
  align-items: center;
`;

export const MockCard = styled.div`
  background: ${({ theme }) => theme.palette.background};
  border-radius: 16px;
  padding: 24px;
  max-width: 148px;
`;
