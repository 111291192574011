import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import ChainId from '../../constants/chainId';
import MetaMaskEvents from '../constants/events';
import { ETH_REQUEST_ACCOUNTS } from '../../constants/ethers';
import MetaMaskErrorCodes, {
  isProviderRpcError,
} from '../constants/errorCodes';
import { LoginData } from '../../../api/requestWrappers/AuthRequest';

interface Args {
  requiredChain?: ChainId;

  onMetaMaskNotFound?(): void;

  onMetaMaskNotConnected?(): void;

  onUserRejectedToConnect?(): void;

  onFailedInGettingTheSigner?(): void;

  onMessageSignaturePending?(): void;

  onMessageSignatureRejected?(): void;

  onAlreadyProcessingEthRequestAccounts?(): void;

  onCouldntGetAddress?(): void;

  userIsLoggedIn?(): boolean;

  onLogin?(loginData: LoginData): void;

  onRequiredChainNotFound?(): void;

  onChainChanged?(): void;

  onAccountsChanged?(): void;
}

const { Web3Provider } = ethers.providers;
const { ethereum } = window;

const useConnectToMetamask = ({
  requiredChain = ChainId.ETHEREUM_MAIN_NET,
  onMetaMaskNotFound = () => null,
  onMetaMaskNotConnected = () => null,
  onAlreadyProcessingEthRequestAccounts = () => null,
  onUserRejectedToConnect = () => null,
  onFailedInGettingTheSigner = () => null,
  onMessageSignaturePending = () => null,
  onMessageSignatureRejected = () => null,
  onCouldntGetAddress = () => null,
  userIsLoggedIn = () => false,
  onLogin = () => null,
  onRequiredChainNotFound = () => null,
  onChainChanged = () => null,
  onAccountsChanged = () => null,
}: Args) => {
  const [signMessagePending, setSignMessagePending] = useState(false);

  const connectWallet = async () => {
    if (!ethereum?.isMetaMask) {
      onMetaMaskNotFound();
      return;
    }

    if (!ethereum?.isConnected()) {
      onMetaMaskNotConnected();
      return;
    }

    if (ethereum?.chainId !== requiredChain) {
      onRequiredChainNotFound();
      return;
    }

    const provider = new Web3Provider(ethereum);
    try {
      await provider.send(ETH_REQUEST_ACCOUNTS, []);

      try {
        const signer = await provider.getSigner();

        if (userIsLoggedIn()) {
          return;
        }

        try {
          if (signMessagePending) {
            onMessageSignaturePending();
            return;
          }

          setSignMessagePending(true);
          const message = Math.random().toString();
          const signature = await signer.signMessage(message);
          setSignMessagePending(false);

          try {
            const address = await signer.getAddress();

            onLogin({ address, signature, message });
          } catch (e) {
            onCouldntGetAddress();
            console.error(e);
          }
        } catch (e) {
          console.error(e);
          if (
            isProviderRpcError(e) &&
            e.code === MetaMaskErrorCodes.REQUEST_REJECTED_BY_USER
          ) {
            setSignMessagePending(false);
            onMessageSignatureRejected();
          }
        }
      } catch (e) {
        console.error(e);
        onFailedInGettingTheSigner();
      }
    } catch (e) {
      if (isProviderRpcError(e)) {
        switch (e.code) {
          case MetaMaskErrorCodes.ALREADY_PROCESSING_ETH_REQUEST_ACCOUNTS:
            onAlreadyProcessingEthRequestAccounts();
            break;
          case MetaMaskErrorCodes.REQUEST_REJECTED_BY_USER:
            onUserRejectedToConnect();
            break;
          default:
          // no default
        }
      }
    }
  };

  useEffect(() => {
    const handleOnChainChanged = () => {
      onChainChanged();
      window.location.reload();
    };

    const handleOnAccountsChanged = () => {
      onAccountsChanged();
      window.location.reload();
    };

    ethereum?.on?.(MetaMaskEvents.CHAIN_CHANGED, handleOnChainChanged);
    ethereum?.on?.(MetaMaskEvents.ACCOUNTS_CHANGED, handleOnAccountsChanged);

    return () => {
      ethereum?.removeListener?.(
        MetaMaskEvents.CHAIN_CHANGED,
        handleOnChainChanged
      );
      ethereum?.removeListener?.(
        MetaMaskEvents.ACCOUNTS_CHANGED,
        handleOnAccountsChanged
      );
    };
  }, [onRequiredChainNotFound, onChainChanged, onAccountsChanged]);

  return { connectWallet };
};

export default useConnectToMetamask;
