import React from 'react';

export function SearchIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 13L10 10M10 10C10.7422 9.25786 11.2012 8.23261 11.2012 7.10015C11.2012 4.83522 9.3651 2.99914 7.10018 2.99914C4.83525 2.99914 2.99917 4.83522 2.99917 7.10015C2.99917 9.36507 4.83525 11.2012 7.10018 11.2012C8.23264 11.2012 9.25789 10.7421 10 10Z"
        stroke="black"
      />
    </svg>
  );
}

export function SearchIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.5 19.5L15.5013 15.5013M15.5013 15.5013C16.6145 14.3881 17.303 12.8502 17.303 11.1515C17.303 7.75413 14.5489 5 11.1515 5C7.75413 5 5 7.75413 5 11.1515C5 14.5489 7.75413 17.303 11.1515 17.303C12.8502 17.303 14.3881 16.6145 15.5013 15.5013Z"
        stroke="black"
      />
    </svg>
  );
}
