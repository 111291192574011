import React from 'react';

export function ApplicationIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.66669 8H10.6667M2.66669 4H8.00002M2.66669 12H10.6667M2.66669 6H13.3334M2.66669 10H8.00002"
        stroke="black"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ApplicationIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 12H18M6 8H16M6 16H12" stroke="black" strokeLinejoin="round" />
    </svg>
  );
}
