import React from 'react';

export function ArrowLeftIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 8L4.64645 7.64645L4.29289 8L4.64645 8.35355L5 8ZM12 7.5H5V8.5H12V7.5ZM5.35355 8.35355L8.35355 5.35355L7.64645 4.64645L4.64645 7.64645L5.35355 8.35355ZM4.64645 8.35355L7.64645 11.3536L8.35355 10.6464L5.35355 7.64645L4.64645 8.35355Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ArrowLeftIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 12L5.64645 11.6464L5.29289 12L5.64645 12.3536L6 12ZM17 11.5H6V12.5H17V11.5ZM6.35355 12.3536L10.8536 7.85355L10.1464 7.14645L5.64645 11.6464L6.35355 12.3536ZM5.64645 12.3536L10.1464 16.8536L10.8536 16.1464L6.35355 11.6464L5.64645 12.3536Z"
        fill="currentColor"
      />
    </svg>
  );
}
