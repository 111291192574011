import { SpinnerIcon24px, SpinnerIcon16px } from 'components/icons/SpinnerIcon';

import * as SC from './styles';

interface SpinnerPropTypes {
  size?: 16 | 24;
  [key: string]: any;
}

function Spinner({ size = 24, ...rest }: SpinnerPropTypes) {
  const getIcon = () => {
    switch (size) {
      case 16:
        return <SpinnerIcon16px />;
      case 24:
      default:
        return <SpinnerIcon24px />;
    }
  };

  return <SC.Wrapper {...rest}>{getIcon()}</SC.Wrapper>;
}

export default Spinner;
