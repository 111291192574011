import React from 'react';

export function CoinPlaceholder24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Coin Placeholder</title>
      <rect width="28" height="28" rx="4" fill="#999999" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 7L22 11L14 21L6 11L9 7H19ZM14 12C14.5523 12 15 11.5523 15 11C15 10.4477 14.5523 10 14 10C13.4477 10 13 10.4477 13 11C13 11.5523 13.4477 12 14 12ZM12 11C12 11.5523 11.5523 12 11 12C10.4477 12 10 11.5523 10 11C10 10.4477 10.4477 10 11 10C11.5523 10 12 10.4477 12 11ZM17 12C17.5523 12 18 11.5523 18 11C18 10.4477 17.5523 10 17 10C16.4477 10 16 10.4477 16 11C16 11.5523 16.4477 12 17 12Z"
        fill="white"
      />
    </svg>
  );
}

export function CoinPlaceholder32px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Coin Placeholder</title>
      <rect width="28" height="28" rx="4" fill="#999999" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 7L22 11L14 21L6 11L9 7H19ZM14 12C14.5523 12 15 11.5523 15 11C15 10.4477 14.5523 10 14 10C13.4477 10 13 10.4477 13 11C13 11.5523 13.4477 12 14 12ZM12 11C12 11.5523 11.5523 12 11 12C10.4477 12 10 11.5523 10 11C10 10.4477 10.4477 10 11 10C11.5523 10 12 10.4477 12 11ZM17 12C17.5523 12 18 11.5523 18 11C18 10.4477 17.5523 10 17 10C16.4477 10 16 10.4477 16 11C16 11.5523 16.4477 12 17 12Z"
        fill="white"
      />
    </svg>
  );
}
