import { MarketRequestTypeApiResponse } from 'api/requestWrappers/MarketRequest';
import FLWebSocket from 'websocket/wrappers/FLWebSocket';

export type Socket = FLWebSocket | null;

export enum NotificationType {
  MARKET_REQUEST_PUBLISHED = 'MarketRequestPublished',
  MARKET_REQUEST_APPLIED = 'MarketRequestApplied',
  MARKET_REQUEST_APPROVED = 'MarketRequestApproved',
  BORROW_EXECUTED = 'BorrowExecuted',
  PRINCIPAL_PAYMENT = 'PrincipalPayment',
  LOAN_MATURITY_1_DAY_REMINDER = 'LoanMaturity1DayReminder',
  LOAN_MATURITY_7_DAYS_REMINDER = 'LoanMaturity7DaysReminder',
  LOAN_MATURITY_1_MONTH_REMINDER = 'LoanMaturity1MonthReminder',
  MONTHLY_RATE_5_DAYS_REMINDER = 'MonthlyRate5DaysReminder',
  MONTHLY_RATE_PAYMENT = 'MonthlyRatePayment',
  OVERDUE_LOAN_PAYMENT = 'OverdueLoanPayment',
  OVERDUE_MONTHLY_RATE_PAYMENT = 'OverdueMonthlyRatePayment',
}

export interface NotificationData {
  id: number;
  userId: number;
  name: NotificationType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  date: string;
}

export interface WsMarketItem {
  amount: string;
  assetAddress: string;
  assetDecimals: number;
  assetName: string;
  id: string;
  type: MarketRequestTypeApiResponse;
}

export interface WsMonthlyRatePayment {
  payment: {
    requestId: number;
    assetAddress: string;
    assetDecimals: number;
    assetName: string;
    amount: string;
    borrowerId: number;
    borrowerName: string;
  };
  loan: {
    id: number;
  };
}

export interface WsOverdueMonthlyRatePayment {
  monthlyRate: {
    requestId: number;
    assetAddress: string;
    assetDecimals: number;
    assetName: string;
    amount: string;
  };
  loan: {
    id: number;
    initialAmount: string;
  };
}

export interface WsOverdueLoanPayment {
  loan: {
    id: number;
    requestId: number;
    initialAmount: string;
    assetAddress: string;
    assetDecimals: number;
    assetName: string;
  };
}

export interface WsPrincipalPayment {
  payment: {
    requestId: number;
    assetAddress: string;
    assetDecimals: number;
    assetName: string;
    amount: string;
    borrowerId: number;
    borrowerName: string;
  };
  loan: {
    id: number;
  };
}

export interface WsMonthlyRate5DaysReminder {
  rate: {
    assetAddress: string;
    assetDecimals: number;
    assetName: string;
    amount: string;
    dueDate: string;
  };
  loan: {
    id: number;
    initialAmount: string;
  };
}

export interface WsLoanMaturityReminder {
  loan: {
    id: number;
    initialAmount: string;
    assetAddress: string;
    assetDecimals: number;
    assetName: string;
    dueDate: string;
  };
}
