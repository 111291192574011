import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { assetsRequest, Asset } from 'api/requestWrappers/AssetsRequest';

export const assets = createAsyncThunk('assets', () =>
  assetsRequest.getBorrowAssets()
);

export interface AssetsState {
  assets: Asset[];
}

const initialState: AssetsState = {
  assets: [],
};

const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(assets.fulfilled, (state: AssetsState, action) => {
      state.assets = action.payload;
    });
  },
});

export default assetsSlice.reducer;
