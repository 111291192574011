export const FLP_API_PROTOCOL =
  process.env.REACT_APP_FLP_API_PROTOCOL || 'http';

export const FLP_API_HOST = process.env.REACT_APP_FLP_API_HOST || 'localhost';

export const FLP_API_PORT = process.env.REACT_APP_FLP_API_PORT || '';

export const FLP_WS_PROTOCOL = process.env.REACT_APP_FLP_WS_PROTOCOL || 'ws';

export const FLP_WS_HOST = process.env.REACT_APP_FLP_WS_HOST || 'localhost';

export const FLP_WS_PORT = process.env.REACT_APP_FLP_WS_PORT || '';

export const FLP_API_ENTRY_ROUTE =
  process.env.REACT_APP_CAFM_API_ENTRY_ROUTE || '/api';

export const FLP_USE_ETHEREUM_MAINNET =
  process.env.REACT_APP_FLP_USE_ETHEREUM_MAINNET === 'true';

export const APP_DEFAULT_TITLE =
  process.env.REACT_APP_APP_DEFAULT_TITLE || 'Filecoin Lending Platform';

export const I18N_DEBUG_MODE = process.env.REACT_APP_I18N_DEBUG_MODE === 'true';
