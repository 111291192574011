import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Button = styled.button`
  width: 40px;
  height: 40px;
  border: 2px solid ${({ theme }) => theme.palette.background};
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: ${({ theme }) => theme.palette.background};
  color: ${({ theme }) => theme.palette.text};

  :hover {
    color: ${({ theme }) => theme.palette.textSecondary};
    border-color: ${({ theme }) => theme.palette.raw.black};
  }
`;
