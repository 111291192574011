export enum TabKeys {
  INFORMATION = 'activeOffers',
  MY_OFFERS = 'myOffers',
  ACTIVE_LOANS = 'activeLoans',
  CLOSED_LOANS = 'closedLoans',
}

export interface TabKeysStateProp {
  [TabKeys.INFORMATION]: number | null;
  [TabKeys.MY_OFFERS]: number | null;
  [TabKeys.ACTIVE_LOANS]: number | null;
  [TabKeys.CLOSED_LOANS]: number | null;
}
