import React, { Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import routes from 'routes';
import { Route as RouteInterface } from 'routes/types';
import StyledToastContainer from 'styles/Toast/Container';
import { logout, me } from './redux/slices/auth';
import { assets } from './redux/slices/assets';
import { userAddressSelector } from './redux/slices/selectors';
import { setUnread, unreadNotifications } from './redux/slices/notifications';
import IconsCatalog from './components/icons';
import AppRoute from './components/dumb/AppRoute';
import { useAppDispatch } from './hooks/redux';
import appChainMismatchWithClientChain from './helpers/constants/appChainMismatchWithClientChain';

const { ethereum } = window;

function App() {
  const dispatch = useAppDispatch();
  const userAddress = useSelector(userAddressSelector);

  useEffect(() => {
    dispatch(me()).then(() => {
      setTimeout(() => {
        if (!ethereum?.isConnected() || appChainMismatchWithClientChain) {
          dispatch(logout());
        }
      }, 500);
    });
    dispatch(assets());
  }, []);

  useEffect(() => {
    if (userAddress) {
      dispatch(unreadNotifications());
    } else {
      dispatch(setUnread(0));
    }
  }, [userAddress]);

  return (
    <>
      <Suspense fallback={<div />}>
        <Switch>
          <Route path="/icons" component={IconsCatalog} />
          {routes.map((route: RouteInterface) => (
            <AppRoute
              key={route.path.toString()}
              component={route.component}
              path={route.path}
              isExact={route.isExact}
              isProtected={route.isProtected}
            />
          ))}
        </Switch>
      </Suspense>
      <StyledToastContainer />
    </>
  );
}

export default App;
