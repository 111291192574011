import React from 'react';
import clsx from 'clsx';

import { KnownTokens } from 'config';
import TypeChecker from 'helpers/classes/TypeChecker';
import { getTokenIcon, TokenIconSize } from 'helpers/functions/getTokenIcon';
import * as SC from './styled';

type PropTypes = {
  assetName?: KnownTokens;
  annualInterestRate?: number;
  size?: TokenIconSize;
  darkBorder?: boolean;
};

function CurrencyOffer({
  assetName,
  annualInterestRate,
  size = TokenIconSize.DEFAULT,
  darkBorder = false,
}: PropTypes) {
  return (
    <SC.Wrapper>
      <SC.IconPlaceholder style={{ width: size, height: size }}>
        {assetName && getTokenIcon(assetName, size)}
      </SC.IconPlaceholder>
      <SC.Percent
        className={clsx({ big: size === TokenIconSize.BIG, darkBorder })}
      >
        {TypeChecker.isNumber(annualInterestRate) ? annualInterestRate : '--'} %
      </SC.Percent>
    </SC.Wrapper>
  );
}

export default CurrencyOffer;
