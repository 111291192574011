import TypeChecker from 'helpers/classes/TypeChecker';
import { MinerResponsePropTypes } from 'api/requestWrappers/FilrepRequest';

export interface CalcReputationScoreItem {
  score: number | null;
  isAvg: boolean | null;
}

export function calcReputationScore(
  data: MinerResponsePropTypes[]
): CalcReputationScoreItem {
  let minersCount = 0;
  let totalScore = 0;

  data.forEach((item) => {
    if (TypeChecker.isNumber(item.score)) {
      totalScore += item.score;
      minersCount += 1;
    }
  });

  return {
    score: minersCount ? totalScore / minersCount : null,
    isAvg: minersCount ? minersCount > 1 : null,
  };
}
