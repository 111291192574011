import useGetAssetValueInEthValue from './useGetAssetValueInEthValue';
import useGetEthPriceInUsd from './useGetEthPriceInUsd';
import { Asset } from '../../api/requestWrappers/AssetsRequest';
import { wei_to_valueInAssetUnits } from '../../helpers/functions/numbers';

const useGetAssetValueInUsdValue = () => {
  const { getAssetValueInEthValue } = useGetAssetValueInEthValue();
  const ethPriceInUsd = useGetEthPriceInUsd();

  const getAssetValueInUsdValue = async (
    asset: Asset,
    rawAmount: string | undefined = undefined
  ): Promise<number | null> => {
    if (!ethPriceInUsd) {
      console.error('ethPriceInUsd is not set.');
      return null;
    }
    return getAssetValueInEthValue(asset, rawAmount).then((ethPricePerUnit) => {
      if (!ethPricePerUnit) {
        return null;
      }

      const amountInEthValue = +wei_to_valueInAssetUnits(
        ethPricePerUnit.toString()
      );

      return amountInEthValue * ethPriceInUsd;
    });
  };

  return { getAssetValueInUsdValue, ethPriceInUsd };
};

export default useGetAssetValueInUsdValue;
