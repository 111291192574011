import React from 'react';

export function SpinnerIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        opacity="0.3"
        x="7"
        y="10.6667"
        width="2"
        height="4.66667"
        rx="1"
        fill="currentColor"
      />
      <rect
        opacity="0.7"
        x="7"
        y="0.666656"
        width="2"
        height="4.66667"
        rx="1"
        fill="currentColor"
      />
      <rect
        opacity="0.5"
        x="5.33337"
        y="7"
        width="2"
        height="4.66667"
        rx="1"
        transform="rotate(90 5.33337 7)"
        fill="currentColor"
      />
      <rect
        opacity="0.9"
        x="15.3334"
        y="7"
        width="2"
        height="4.66667"
        rx="1"
        transform="rotate(90 15.3334 7)"
        fill="currentColor"
      />
      <rect
        opacity="0.4"
        x="5.40723"
        y="9.17853"
        width="2"
        height="4.66667"
        rx="1"
        transform="rotate(45 5.40723 9.17853)"
        fill="currentColor"
      />
      <rect
        opacity="0.8"
        x="12.4784"
        y="2.10745"
        width="2"
        height="4.66667"
        rx="1"
        transform="rotate(45 12.4784 2.10745)"
        fill="currentColor"
      />
      <rect
        opacity="0.6"
        x="6.82153"
        y="5.40729"
        width="2"
        height="4.66667"
        rx="1"
        transform="rotate(135 6.82153 5.40729)"
        fill="currentColor"
      />
      <rect
        opacity="0.2"
        x="13.8926"
        y="12.4783"
        width="2"
        height="4.66667"
        rx="1"
        transform="rotate(135 13.8926 12.4783)"
        fill="currentColor"
      />
    </svg>
  );
}

export function SpinnerIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        opacity="0.3"
        x="10.5"
        y="16"
        width="3"
        height="7"
        rx="1.5"
        fill="currentColor"
      />
      <rect
        opacity="0.7"
        x="10.5"
        y="1"
        width="3"
        height="7"
        rx="1.5"
        fill="currentColor"
      />
      <rect
        opacity="0.5"
        x="8"
        y="10.5"
        width="3"
        height="7"
        rx="1.5"
        transform="rotate(90 8 10.5)"
        fill="currentColor"
      />
      <rect
        opacity="0.9"
        x="23"
        y="10.5"
        width="3"
        height="7"
        rx="1.5"
        transform="rotate(90 23 10.5)"
        fill="currentColor"
      />
      <rect
        opacity="0.4"
        x="8.11096"
        y="13.7678"
        width="3"
        height="7"
        rx="1.5"
        transform="rotate(45 8.11096 13.7678)"
        fill="currentColor"
      />
      <rect
        opacity="0.8"
        x="18.7175"
        y="3.16116"
        width="3"
        height="7"
        rx="1.5"
        transform="rotate(45 18.7175 3.16116)"
        fill="currentColor"
      />
      <rect
        opacity="0.6"
        x="10.2323"
        y="8.1109"
        width="3"
        height="7"
        rx="1.5"
        transform="rotate(135 10.2323 8.1109)"
        fill="currentColor"
      />
      <rect
        opacity="0.2"
        x="20.8389"
        y="18.7175"
        width="3"
        height="7"
        rx="1.5"
        transform="rotate(135 20.8389 18.7175)"
        fill="currentColor"
      />
    </svg>
  );
}
