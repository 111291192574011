import { FLP_USE_ETHEREUM_MAINNET } from '../../helpers/constants/envVars';

const lendingPoolV2AddressKovan = '0xE0fBa4Fc209b4948668006B2bE61711b7f465bAe';

const lendingPollV2AddressMainnet =
  '0x7d2768dE32b0b80b7a3454c06BdAc94A69DDc7A9';

export const lendingPoolV2Address = FLP_USE_ETHEREUM_MAINNET
  ? lendingPollV2AddressMainnet
  : lendingPoolV2AddressKovan;

const priceOracleAddressKovan = '0xB8bE51E6563BB312Cbb2aa26e352516c25c26ac1';

const priceOracleAddressMainnet = '0xA50ba011c48153De246E5192C8f9258A2ba79Ca9';

export const priceOracleAddress = FLP_USE_ETHEREUM_MAINNET
  ? priceOracleAddressMainnet
  : priceOracleAddressKovan;
