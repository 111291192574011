import styled from 'styled-components';

export const TargetWrapper = styled.div`
  display: inline-flex;
`;

export const Popper = styled.div`
  background: ${({ theme }) => theme.palette.raw.black};
  padding: 7px 10px 8px;
  border-radius: 6px;
  font-size: ${({ theme }) => theme.typography.pxToRem(-4)};
  font-style: normal;
  font-weight: 600;
  line-height: ${({ theme }) => theme.typography.fontSize};
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.palette.raw.white};
`;

export const Arrow = styled.div`
  width: 0;
  height: 0;
  position: absolute;

  &[data-placement='top'] {
    top: 100%;
  }

  &[data-placement='bottom'] {
    bottom: 100%;
  }

  &::before {
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    background-color: ${({ theme }) => theme.palette.raw.black};
    position: absolute;
    left: 0;
    transform: translateX(-50%) rotate(45deg);
    border-radius: 2px;
  }

  &[data-placement='top']::before {
    bottom: -3px;
  }

  &[data-placement='bottom']::before {
    top: -3px;
  }
`;
