import { DefaultTheme } from 'styled-components';

import pxToRem from 'helpers/functions/styling';

const theme: DefaultTheme = {
  palette: {
    text: '#999',
    textSecondary: '#000',
    background: '#f5f5f5',
    separators: {
      whiteBg: '#ebebeb',
      altWhiteBg: '#424242',
      darkerBg: '#e0e0e0',
      allBlackBg: '#2c2c2c',
    },
    raw: { white: '#fff', black: '#000' },
    sets: {
      green: {
        light: '#def7e5',
        main: '#2ab952',
        dark: '#003c11',
      },
      yellow: {
        light: '#fdf4e2',
        main: '#f4af2a',
        dark: '#5b4512',
      },
      red: {
        light: '#fee5e2',
        main: '#f7472c',
        dark: '#621e16',
      },
    },
    pieChartColors: ['#F4B173', '#A678F1', '#5E6CEC', '#89D53D', '#C38DDC'],
  },
  typography: {
    fontFamily: 'Space Grotesk',
    fontSize: '1rem',
    fontWeight: 500,
    pxToRem,
  },
};

export default theme;
