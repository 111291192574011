import styled from 'styled-components';
import { ReactElement } from 'react';

interface Props {
  isDisabled?: boolean;
}

const Container = styled.button<Props>`
  text-align: left;
  line-height: 18px;
  padding: 16px;
  border: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.palette.text : theme.palette.sets.green.main};
  background-color: ${({ theme, isDisabled }) =>
    isDisabled
      ? theme.palette.separators.whiteBg
      : theme.palette.sets.green.light};
  text-decoration: ${({ isDisabled }) =>
    isDisabled ? 'initial' : 'underline'};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`;

const AddIconWrapper = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.palette.raw.white};
  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.palette.text : theme.palette.sets.green.main};
  border-radius: 16px;

  svg path {
    fill: ${({ theme }) => theme.palette.raw.white};
  }
`;

const Text = styled.div<Props>``;

function Icon({ isDisabled, children }: Props & { children: ReactElement }) {
  return <AddIconWrapper isDisabled={isDisabled}>{children}</AddIconWrapper>;
}

export default {
  Container,
  Icon,
  Text,
};
