import { Route } from './types';
import marketRoutes from './market';

// eslint-disable-next-line import/no-mutable-exports
let routes: Route[] = [];
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const routeFiles = require.context(
  './',
  true,
  /^(?!.\/index|.\/market|.\/paths|.\/patterns|.\/types|routes).*\.ts$/
);

routeFiles.keys().forEach((routeFile: string) => {
  routes = [...routes, ...routeFiles(routeFile).default];
});

routes = [...routes, ...marketRoutes];

export default routes;
