import AbstractRequest from './AbstractRequest';
import { ApiMethod } from './constants';

interface GetMyNotificationsRequestsData {
  limit: number;
  offset: number;
}

export class NotificationsRequest extends AbstractRequest {
  get apiResource(): string {
    return 'notifications';
  }

  get routes(): Record<string, string> {
    return {
      MY_NOTIFICATIONS: `${this.apiRoute}/me`,
      GET_UNREAD: `${this.apiRoute}/unread`,
      POST_READ: `${this.apiRoute}/read`,
    };
  }

  async getMyNotifications(data: GetMyNotificationsRequestsData) {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.MY_NOTIFICATIONS +
        this.buildQueryString(this.buildPaginationQueryParams(data))
    );

    return result?.data;
  }

  async getUnread(): Promise<number> {
    const result = await this.request(ApiMethod.GET, this.routes.GET_UNREAD);

    return result?.data.unread;
  }

  async postRead(notificationIds: number[]) {
    const result = await this.request(ApiMethod.POST, this.routes.POST_READ, {
      notificationIds,
    });

    return result?.data;
  }

  buildPaginationQueryParams(data: GetMyNotificationsRequestsData): string {
    const queryParams: string[] = [];
    Object.entries(data).forEach(([key, value]) => {
      queryParams.push(`&${key}=${value}`);
    });

    return queryParams.join('');
  }

  buildQueryString(...queryParams: string[]) {
    let queryString = '';
    let firstQueryParamSet = false;
    queryParams
      .filter((queryParam) => queryParam)
      .forEach((queryParam) => {
        if (firstQueryParamSet) {
          queryString += queryParam;
        } else {
          queryString += `?${queryParam.slice(1)}`;
          firstQueryParamSet = true;
        }
      });

    return queryString;
  }
}

export const notificationsRequest = new NotificationsRequest();
