import React from 'react';

export function ExternalAltIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 4H12.5V3.5H12V4ZM4 4V3.5H3.5V4H4ZM4 12H3.5V12.5H4V12ZM12 12V12.5H12.5V12H12ZM7.35355 9.35355L12.3536 4.35355L11.6464 3.64645L6.64645 8.64645L7.35355 9.35355ZM12 3.5H8V4.5H12V3.5ZM11.5 4V8H12.5V4H11.5ZM6 3.5H4V4.5H6V3.5ZM3.5 4V12H4.5V4H3.5ZM4 12.5H12V11.5H4V12.5ZM12.5 12V10H11.5V12H12.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ExternalAltIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 6H18.5V5.5H18V6ZM6 6V5.5H5.5V6H6ZM6 18H5.5V18.5H6V18ZM18 18V18.5H18.5V18H18ZM10.8536 13.8536L18.3536 6.35355L17.6464 5.64645L10.1464 13.1464L10.8536 13.8536ZM18 5.5H12V6.5H18V5.5ZM17.5 6V12H18.5V6H17.5ZM9 5.5H6V6.5H9V5.5ZM5.5 6V18H6.5V6H5.5ZM6 18.5H18V17.5H6V18.5ZM18.5 18V15H17.5V18H18.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
