import React from 'react';

export function DeleteIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.66663 5.03703H3.85181M13.3333 5.03703H12.1481M3.85181 5.03703L4.4444 13.3333H11.5555L12.1481 5.03703M3.85181 5.03703H5.62959M12.1481 5.03703H10.3703M10.3703 5.03703L9.77774 2.66666H6.22218L5.62959 5.03703M10.3703 5.03703H5.62959M6.51848 7.70369L7.99996 9.18517M9.48144 10.6667L7.99996 9.18517M9.48144 7.70369L7.99996 9.18517M7.99996 9.18517L6.51848 10.6667"
        stroke="black"
      />
    </svg>
  );
}

export function DeleteIcon24px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 7.55556H5.77778M20 7.55556H18.2222M5.77778 7.55556L6.66667 20H17.3333L18.2222 7.55556M5.77778 7.55556H8.44444M18.2222 7.55556H15.5556M15.5556 7.55556L14.6667 4H9.33333L8.44444 7.55556M15.5556 7.55556H8.44444M9.77778 11.5556L12 13.7778M14.2222 16L12 13.7778M14.2222 11.5556L12 13.7778M12 13.7778L9.77778 16"
        stroke="currentColor"
      />
    </svg>
  );
}
