import { HealthValues } from 'config';
import * as SC from './styled';

interface HealthBarPropTypes {
  health: number;
}

function HealthBar({ health }: HealthBarPropTypes) {
  const getPercent = (): number => {
    if (health > HealthValues.MAX) {
      return 100;
    }
    if (health < HealthValues.MIN) {
      return 0;
    }
    return (health / (HealthValues.MAX - HealthValues.MIN)) * 100;
  };

  return <SC.Wrapper percent={getPercent()} />;
}

export default HealthBar;
