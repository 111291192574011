import AbstractRequest from './AbstractRequest';
import { ApiMethod } from './constants';

export interface Asset {
  id: number;
  address: string;
  name: string;
  decimals: number;
  enabled: boolean;
  createdAt: string;
  variableDebtTokenAddress: string;
}

export class AssetsRequest extends AbstractRequest {
  get apiResource(): string {
    return 'aave';
  }

  get routes(): Record<string, string> {
    return {
      GET_BORROW_ASSETS: `${this.apiRoute}/borrow-assets`,
    };
  }

  async getBorrowAssets(): Promise<Asset[]> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_BORROW_ASSETS
    );

    return result?.data;
  }
}

export const assetsRequest = new AssetsRequest();
