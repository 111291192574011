import { ApiMethod } from './constants';
import AbstractExternalRequest from './AbstractExternalRequest';
import TypeChecker from '../../helpers/classes/TypeChecker';

export interface MinerResponseData {
  address: string;
  score: string;
  rawPower: string;
}

export const isMinerResponseData = (obj: any): obj is MinerResponseData =>
  TypeChecker.isString(obj?.address) &&
  TypeChecker.isString(obj?.score) &&
  TypeChecker.isString(obj?.rawPower);

export interface MinerResponsePropTypes {
  address: string;
  score: number;
  size: string;
}

export class FilrepRequest extends AbstractExternalRequest {
  get apiUrl(): string {
    return 'https://api.filrep.io/api';
  }

  get searchUrl(): string {
    return 'https://filrep.io/?search=';
  }

  get dynamicIds(): Record<string, string> {
    return {
      MINER_ID: '{minerId}',
      LIMIT: '{limit}',
    };
  }

  get routes(): Record<string, string> {
    return {
      GET_MINER: `${this.apiUrl}/miners?limit=${this.dynamicIds.LIMIT}&offset=0&search=${this.dynamicIds.MINER_ID}`,
    };
  }

  async getMiner(minerId: string): Promise<MinerResponsePropTypes[]> {
    const response = await this.request(
      ApiMethod.GET,
      this.routes.GET_MINER.replace(this.dynamicIds.MINER_ID, minerId).replace(
        this.dynamicIds.LIMIT,
        '1'
      )
    );

    return response?.data?.miners?.map(
      ({ address, score, rawPower }: MinerResponseData) => ({
        address,
        score: Number(score),
        size: rawPower,
      })
    );
  }

  async getMiners(
    minerIds: string[]
  ): Promise<Record<string, MinerResponsePropTypes>> {
    const response = await this.request(
      ApiMethod.GET,
      this.routes.GET_MINER.replace(
        this.dynamicIds.MINER_ID,
        minerIds.join(',')
      ).replace(this.dynamicIds.LIMIT, minerIds.length.toString())
    );

    const minerResponseDictionary: Record<string, MinerResponsePropTypes> =
      response?.data?.miners?.reduce(
        (
          accumulator: Record<string, MinerResponsePropTypes>,
          { address, score, rawPower }: MinerResponseData
        ) => {
          accumulator[address] = {
            address,
            score: Number(score),
            size: rawPower,
          };
          return accumulator;
        },
        {}
      );

    return minerResponseDictionary;
  }
}

export const filrepRequest = new FilrepRequest();
