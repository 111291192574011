import AuthClientStore from '../../clientStore/AuthClientStore';

export default class FLWebSocket extends WebSocket {
  sendJwtToken() {
    this.send(
      JSON.stringify({
        event: 'message',
        data: { jwt: AuthClientStore.getToken() ?? 'noToken' },
      })
    );
  }
}
