import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export function InfoAltIcon16px(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 4.5V6M8 11.3333V7" stroke="black" strokeLinejoin="round" />
    </svg>
  );
}
